import React, { useEffect, useState, useCallback } from 'react';
import {
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import BarcodeIcon from '../../icons/barcodeIcon.svg';
import { debounce } from 'lodash';

interface BarcodeItem {
    id: string;
    sno: number;
    barcode: string;
    brandName: string;
    productName: string;
    description: string;
    sku: string;
    requestedDate: string;
    assignedDate?: string;
    employeeName: string;
    empNo: string;
    assignee: string;
}

interface BarcodeTableProps {
    searchTerm: string;  // Search term as a prop
    showFilteredData: boolean; // Flag to determine if filtered data should be shown
    selectedBrands: string[];
    selectedProducts: string[];
    selectedBarcodeStatus: string[];
    filtersApplied: boolean;  // A flag to check if filters are applied
    loadMoreBarcodes: (
        page: number,
        isMoreData: boolean,
        searchText: string,
        selectedBrandsList: string[],
        selectedProductsList: string[],
        selectedBarcodeStatus: string[],
        columns: string,
        directions: string
      ) => void;
    page: number;
    moreData: boolean;
    rows: BarcodeItem[];
    setPage: React.Dispatch<React.SetStateAction<number>>; // Setter function for page state
    setMoreData: React.Dispatch<React.SetStateAction<boolean>>; // Setter function for moreData state
    setRows: React.Dispatch<React.SetStateAction<BarcodeItem[]>>; // Setter function for rows state
}

const BarcodeTable: React.FC<BarcodeTableProps> = ({
    searchTerm,
    showFilteredData,
    selectedBrands,
    selectedProducts,
    selectedBarcodeStatus,
    filtersApplied,
    loadMoreBarcodes,
    page, moreData, rows, setPage, setMoreData, setRows
}) => {

    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc'); // Sorting direction
    const [sortColumn, setSortColumn] = useState<'barcode' | 'requestedDate'>('requestedDate'); // Column to sort
    
    useEffect(() => {
        if (filtersApplied) {
            setPage(0);
            setRows([]); // Clear previous rows when search term changes
            setMoreData(true); // Reset the flag for more data
            loadMoreBarcodes(0,true,searchTerm, selectedBrands, selectedProducts,selectedBarcodeStatus,sortColumn,sortDirection); // Trigger data fetch when filters are applied
        }
    }, [filtersApplied, searchTerm, sortColumn,sortDirection]); // Re-run whenever filters or search term change

    
    // Debounced search function to delay API calls while typing
    const debouncedSearch = useCallback(
        debounce((searchTerm: string, selectedBrands: string[], selectedProducts: string[],selectedBarcodeStatus:string[],sortColumn:string,sortDirection:string) => {
            setRows([]); // Clear previous rows when search term changes
            setPage(0); // Reset to page 0 when searchTerm changes
            setMoreData(true); // Reset the flag for more data
            loadMoreBarcodes (0,true,searchTerm, selectedBrands, selectedProducts,selectedBarcodeStatus,sortColumn,sortDirection);
        }, 500), // 500ms delay for debouncing
        []
    );

    // Effect to trigger search and data load based on searchTerm
    useEffect(() => {
        debouncedSearch(searchTerm, selectedBrands, selectedProducts,selectedBarcodeStatus,sortColumn,sortDirection); // Trigger the debounced search whenever the searchTerm changes
    }, [searchTerm, sortDirection,sortColumn]);
   
    // Handle sort change
    const handleSort = (column: 'barcode' | 'requestedDate') => {
        const isAsc = sortColumn === column && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setSortColumn(column);
        setPage(0);
        // setSize(size);
    };

    useEffect(() => {
        if (showFilteredData) {
            setMoreData(false);
        }
    }, [showFilteredData]);

    // Format date for display
    const formatDate = (dateString: any) => {
        if (!dateString) {
            return '-';
        }
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };
    
    return (
        <Paper style={{ padding: '20px' }}>
            <TableContainer>
                <InfiniteScroll
                    dataLength={rows.length} // Number of items already loaded
                    next={() => loadMoreBarcodes (page,moreData,searchTerm, selectedBrands, selectedProducts,selectedBarcodeStatus,sortColumn,sortDirection)} // Function to load more data with searchTerm
                    hasMore={moreData} // Whether there is more data to load
                    loader={<div style={{ textAlign: 'center', padding: '2vh' }}><CircularProgress /></div>} // Loader while fetching
                    scrollThreshold={0.9} // Trigger `next` when 90% of the list is scrolled
                    scrollableTarget="table-container" // Target the scroll of the TableContainer specifically
                >
                    <Table aria-label="barcode table">
                        <TableHead style={{ backgroundColor: '#f0f0f0' }}>
                            <TableRow>
                                {['S.No', 'GTIN', 'Brand', 'Product', 'Description', 'SKU', 'Requested Date', 'Approved Date', 'Requestee Name', 'Employee ID', 'Assignee'].map((header) => (
                                    <TableCell key={header} sx={{ fontWeight: 'bold' }}>
                                        {header === 'GTIN' || header === 'Requested Date' ? (
                                            <TableSortLabel
                                                active={sortColumn === (header === 'GTIN' ? 'barcode' : 'requestedDate')}
                                                direction={sortColumn === (header === 'GTIN' ? 'barcode' : 'requestedDate') ? sortDirection : 'asc'}
                                                onClick={() => handleSort(header === 'GTIN' ? 'barcode' : 'requestedDate')}
                                            >
                                                {header}
                                            </TableSortLabel>
                                        ) : (
                                            header
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.length > 0 && (
                                rows.map((barcodeList: BarcodeItem, index: number) => (
                                    <TableRow key={barcodeList.id}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                            {barcodeList.barcode ? (
                                                <>
                                                    <span>{barcodeList.barcode}</span>&nbsp;&nbsp;
                                                    <img src={BarcodeIcon} alt="barcode" style={{ cursor: 'pointer' }} />
                                                </>
                                            ) : (
                                                <span>-</span>
                                            )}
                                        </TableCell>
                                        <TableCell>{barcodeList.brandName}</TableCell>
                                        <TableCell>{barcodeList.productName}</TableCell>
                                        <TableCell sx={{ maxWidth: 100, whiteSpace: 'normal', wordWrap: 'break-word', overflow: 'visible', textOverflow: 'ellipsis' }}>
                                            <Tooltip title={barcodeList.description} arrow placement="left-start">
                                                <span>{barcodeList.description}</span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>{barcodeList.sku}</TableCell>
                                        <TableCell>{formatDate(barcodeList.requestedDate)}</TableCell>
                                        <TableCell>
                                            {barcodeList.assignedDate ? (
                                                <span style={{ color: 'green', backgroundColor: '#1C98191A', borderRadius: '5px', padding: '4px' }}>
                                                    {formatDate(barcodeList.assignedDate)}
                                                </span>
                                            ) : (
                                                <span>-</span>
                                            )}
                                        </TableCell>
                                        <TableCell>{barcodeList.employeeName}</TableCell>
                                        <TableCell>{barcodeList.empNo}</TableCell>
                                        <TableCell>{barcodeList.assignee}</TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </InfiniteScroll>
            </TableContainer>
        </Paper>
    );
};

export default BarcodeTable;
