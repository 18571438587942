import { Button, Typography, Stack } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { role } from "../../services/session";
import Box from "../../icons/box.png";
import Barcode from "../../icons/barcode.png";
import { setBarcodeType } from '../../redux/slice/barcodeSlice';
import { useDispatch } from 'react-redux';

// Define a type for the CardItem props
interface CardItemProps {
    image: string;
    title: string;
    description: string;
    buttons?: { label: string, onClick: () => void }[]; // Accept multiple buttons
}

const cardStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',  // Ensures all cards are aligned from the top
    border: '2px solid #f0f0f0',
    boxShadow: '0px 14px 14px 0px rgba(168, 168, 168, 0.6)',
    paddingBlock: '2vh',
    paddingInline: '2vh',
    borderRadius: '8px',
    margin: '2vh',
    width: '500px',
    backgroundColor: 'white',
    marginBottom: '2vh',
    boxSizing: 'border-box',
};

const imgStyles: React.CSSProperties = {
    height: '160px',
    width: '200px',
    marginBottom: '1vh',
};

const textStyles: React.CSSProperties = {
    marginBlockStart: '1vh',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    textAlign: 'center',
};

const buttonStyles: React.CSSProperties = {
    marginBlockStart: '1vh',
    textTransform: 'none',
    paddingInline: '5vh',
    borderRadius: '7px',
};

const cardDescriptionStyles: React.CSSProperties = {
    marginBlock: '1vh',
    marginInlineEnd: '3vh',
    textAlign: 'left',
    padding: '10px',
    flexGrow: 1, // Allow the description to fill the remaining space and adjust the height
};

const CardItem: React.FC<CardItemProps> = ({ image, title, description, buttons }) => (
    <div style={cardStyles}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '100%' }}>
            <img src={image} style={imgStyles} alt={title} />
            <Typography variant="h6" sx={textStyles}>{title}</Typography>
            <Typography variant="body2" sx={cardDescriptionStyles}>{description}</Typography>

            {/* Use Stack to align buttons horizontally */}
            <Stack spacing={2} direction="row" alignItems="center">
                {buttons?.map((button, index) => (
                    <Button key={index} size="small" variant="contained" onClick={button.onClick} sx={buttonStyles}>
                        {button.label}
                    </Button>
                ))}
            </Stack>
        </div>
    </div>
);

const Homepage = () => {
    const dispatch = useDispatch();
    const router = useNavigate();
    const roles: any = role();

    // Updated handler to accept 'domestic' or 'packaging'
    const handleBarcodeClick = (type: 'ean' | 'packaging' | 'upc') => {
        // Store barcodeType in localStorage
        localStorage.setItem('barcodeType', type);
        // Set the barcode type in the Redux store
        dispatch(setBarcodeType(type));

        // Navigate based on role
        if (roles === 'SuperAdmin') {
            if (type === 'packaging') {
                router('/productView');  // For Packaging Library
                window.location.reload();
            } else {
                router('/barcodeRequest');
            }
        } else if (['Employee', 'develop', 'Admin'].includes(roles)) {
            if (type === 'packaging') {
                router('/productView');  // For Packaging Library
                window.location.reload();
            } else {
                router('/barcodeListEmp');
            }
        }
    };

  
    return (
        // <div style={{ backgroundImage: `url(${require('../../icons/bg.png')})`, backgroundSize: 'fill' }}>
        <div style={{ backgroundImage: `url(${require('../../icons/BG.gif')})`, backgroundSize: 'cover',display: 'flex',justifyContent: 'center', height:'100%' }}>
            <div style={{ display: 'flex', marginTop: '10%', marginBottom: '4.9%', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '60px', alignItems: 'stretch',height:'70%' }}>

                {/* Packaging Library Card */}
                <CardItem
                    image={Box}
                    title="Packaging Library"
                    description="Your next big idea might just be a click away. Click 'View' to access an exclusive library of premium mockups, bespoke carton structures, elegant glass formats, and high-quality plastic packaging solutions—curated to inspire and innovate."
                    buttons={[{ label: 'View', onClick: () => handleBarcodeClick('packaging') }]}
                />

                {/* Domestic Barcode Management Card */}
                <CardItem
                    image={Barcode}
                    title="Barcode Management"
                    description="Click View to raise new barcode requests, view your existing barcode list, and manage all submissions in one place. Quickly verify barcode numbers and descriptions prior to artwork approval."
                    buttons={[
                        { label: 'EAN View', onClick: () => handleBarcodeClick('ean') },
                        { label: 'UPC View', onClick: () => handleBarcodeClick('upc') },
                    ]}
                />

            </div>
        </div>
        
    );
};

export default Homepage;
