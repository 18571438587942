export const mouldingProcess = [
    { id: "Extrusion Blow Moulding", mouldingName: 'Extrusion Blow Moulding' },
    { id: "Injection Blow Moulding", mouldingName: 'Injection Blow Moulding' },
    { id: "Injection Stretch Blow Moulding", mouldingName: 'Injection Stretch Blow Moulding' },
    { id: "Injection Moulding", mouldingName: 'Injection Moulding' },



];

export const productAvailability = [
    {id: "Now Available", productAvailabilityName: 'Now Available'},
    {id: "Coming Soon", productAvailabilityName: 'Coming Soon'},
    {id: "Off the shelf", productAvailabilityName: 'Off the shelf'},
    {id: "New Mould", productAvailabilityName: 'New Mould'},
    {id: "Prototype", productAvailabilityName: 'Prototype'}
];

export const productAvailabilityFlexy = [
    {id: "New Structure", productAvailabilityName: 'New Structure'},
    {id: "Existing Structure", productAvailabilityName: 'Existing Structure'},
    {id: "Shape To be developed", productAvailabilityName: 'Shape To be developed'},
    {id: "Shape is readily available", productAvailabilityName: 'Shape is readily available'},
];

export const productAvailabilityImpression = [
    {id: "Off the shelf", productAvailabilityName: 'Off the shelf'},
    {id: "Prototype", productAvailabilityName: 'Prototype'},
    {id: "Available", productAvailabilityName: 'Available'},
    {id: "to be developed", productAvailabilityName: 'to be developed'},
];

export const closureSuitability = [
    { id: "Pump", closureSuitabilityName: 'Pump'},
    { id: "Flip Top cap", closureSuitabilityName: 'Flip Top cap'},
    { id: "Screw on Cap", closureSuitabilityName: 'Screw on Cap'},
    { id: "Airless Pump", closureSuitabilityName: 'Airless Pump'},
    { id: "Friction Fit cap", closureSuitabilityName: 'Friction Fit cap'},
    { id: "Crown cap", closureSuitabilityName: 'Crown cap'},
    { id: "Atomizer", closureSuitabilityName: 'Atomizer'},
    { id: "Foaming Pump", closureSuitabilityName: 'Foaming Pump'},
];

export const productType = [
    {
        id: "international", productTypeName: 'international'
    },
    {
        id: "domestic", productTypeName: 'domestic'
    }
];