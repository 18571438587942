import Header from "./header";
import { Box, CircularProgress, CssBaseline } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { handleAuthValidate, setAuthSession } from "../services/auth-services";
import { fetchAuthLogin } from "../services/product.service";
import aadService from "../authProvider";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Layout = ({ children }: any) => {

  const navigate = useNavigate();


  const accessToken = localStorage.getItem("AccessToken");
  const [isLogin, setIsLogin] = useState(false);

  const fetchLogin = async () => {
    const getValidation = handleAuthValidate();
    if (getValidation?.stage === 1) {
      // if (localStorage.getItem('AccessToken')) {
      //   console.log('');
      // }
      // else {
      await fetchAuthLogin().then((response: any) => {
        setAuthSession(response);
        toast.success(`You have successfully authenticated with Microsoft Login.`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLogin(true);
      }).catch((error) => {
        if (error?.length !== 0 && error !== undefined) {
          setIsLogin(true);
          navigate('/userNotFound');
        }
        else {
          toast.error('Internal Server Error');
        }
        // toast.error(error?.length !== 0 && error !== undefined ? error : `Internal Server Error`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      });
      // }
    }
    else if (getValidation !== undefined) {
      aadService.login();
    }

  };

  useEffect(() => {
    if (!accessToken) {
      fetchLogin();
      // alert(accessToken)
    } else {
      setIsLogin(true);
    }
  }, [accessToken]);


  return (
    <Box>
      <CssBaseline />
      <Header isLogin={isLogin} />
      {isLogin ? (<Box component="main" sx={{ flexGrow: 1, }}>
        {children}
      </Box>) : (<Box sx={{ height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
        <CircularProgress /> </Box>)}
    </Box>



  );
};
export default memo(Layout);