import { AUTH, PRODUCT_LIST, PRODUCT_LIST_MASTER } from '../constants/api-constants';
import { apiGet, apiPost, apiDelete } from "../services/api.services";
import { authToken } from './session';

export const fetchProducts = ({ data, type }: any) => {
    return apiPost(`${PRODUCT_LIST}/${type}/data`, data);
};
export const fetchProductsById = ({ id, type }: any) => {
    return apiGet(`${PRODUCT_LIST}/${type}/${id}`);
};
export const fetchProductDropDownList = (type: any) => {
    return apiGet(`${PRODUCT_LIST_MASTER}/${type}`);
};
export const createProductList = (type: any, data: any) => {
    return apiPost(`${PRODUCT_LIST}/${type}`, data);
};
export const fetchSuggestion = ({ data, type }: any) => {
    return apiGet(`${PRODUCT_LIST}/${type}/data/${data}`);
};
export const productFileUpload = (data: any) => {
    return apiPost(`/image/upload`, data);
};

export const fetchProductsDeleteById = ({ id, type }: any) => {
    return apiDelete(`${PRODUCT_LIST}/${type}/${id}`);
};

///compare 
export const fetchCompareProductsById = ({ ids, type }: any) => {
    switch (ids?.length) {
        case 1: return apiGet(`${PRODUCT_LIST}/compare?ids=${ids[0]}`);
        case 2: return apiGet(`${PRODUCT_LIST}/compare?ids=${ids[0]}&&ids=${ids[1]}`);
        case 3: return apiGet(`${PRODUCT_LIST}/compare?ids=${ids[0]}&&ids=${ids[1]}&&ids=${ids[2]}`);

    }
};

export const fetchAuthLogin = () => {
    return apiGet(`${AUTH}?token=${localStorage.getItem('adal.idtoken')}`);
};