// adalService.js
import AuthenticationContext from 'adal-angular';

const adalConfig: any = {
  clientId: process.env.REACT_APP_CLIENT_ID,
  cacheLocation: 'localStorage', // Choose where tokens should be cached
  redirectUri: window.location.href,
  popUp: false
};

const authContext = new AuthenticationContext(adalConfig);


const aadService = {
  authContext,

  login() {
    this.authContext.login();
    // console.log(this.authContext ,"this.authContext.getCachedUser()");
  },

  logout() {
    localStorage.clear();
    window.location.reload();
    this.authContext.logOut();
  },

  getValidate() {
    // console.log(window.location.href, 'window.location.href');
    authContext.handleWindowCallback();

    // Check if the user is authenticated
    if (authContext.getCachedUser()) {
      // Authentication was successful
      const user = authContext.getCachedUser();
      return { stage: 1, msg: `Hi ${user.userName},Please click to go to your landing page.` };
    } else if (authContext.getLoginError()) {
      // Authentication failed
      return { stage: 2, msg: `Authentication error` };
    } else {
      return { stage: 3, msg: `Please login your microsoft account` };
    }
  },
  getToken() {
    return new Promise((resolve, reject) => {
      this.authContext.acquireToken('https://graph.microsoft.com', (error: any, token: any) => {
        // console.log(token, "token");
        if (error || !token) {
          reject(error);
        } else {
          resolve(token);
        }
      });
    });
  },
};

export default aadService;
