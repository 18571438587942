// import { Typography, Box, Grid, Paper } from '@mui/material';
// import ProductDetailsHead from '../product-detail-component/product-head-button';
// import { compareStyles } from '../../styles/compare.style';
// import { useNavigate } from "react-router-dom";
// import View3D from "@egjs/react-view3d";
// import "@egjs/react-view3d/css/view3d-bundle.min.css";
// import Context from '../../hooks/context';
// import { imageHandle } from '../../services/image-handle.service';
// import { useContext } from 'react';

// const compareContainer = {
//     padding: { md: '20px 0px 0px 25px', xs: '10px 10px 30px 10px', sm: '35x 0px 30px 10px' },
//     display: 'flex',
//     flexWrap: 'nowrap',
//     overflowX: 'scroll',
// };

// const compareBox = {
//     position: 'relative',
//     bottom: { xs: '10px' },
//     flex: '0 0 auto',
//     width: '100%',
//     display: 'flex',
//     justifyContent: 'space-between',
//     marginTop: { md: "140px", xs: '130px', sm: '170px' },
// };

// const subHeader = {
//     width: { xs: '100%', sm: '100%', md: '99%' },
//     position: 'fixed',
//     zIndex: 2,
//     background: 'white',
//     marginLeft: { xs: '-1px', md: 'initial', sm: 'initial' },
// };

// const CompareProduct = ({ handleClickCompare, handleClickOpenModal, type, ids }: any) => {
//     const contextData: any = useContext(Context);
//     const router = useNavigate();

//     return (
//         <>
//             <Grid sx={compareContainer} container spacing={1}>
//                 <Grid sx={subHeader} mt={{ md: 11, xs: 10, sm: 11 }}>
//                     <ProductDetailsHead
//                         page="compare"
//                         data={contextData.compareList}
//                         handleClickCompare={handleClickCompare}
//                     />
//                 </Grid>

//                 <Box id="box-wrapper" p={{ md: 3, sm: 2, xs: 1 }} mt={{ md: 1, sm: 2, xs: 2 }} sx={compareBox}>
//                     {contextData.compareList?.map((item: any, index: number) => {
//                         const images = imageHandle(item.imageURLs);
//                         const mainImage = images?.glb || images?.thumbImage[1] || images?.thumbImage[0];

//                         return (
//                             <Grid
//                                 key={index}
//                                 mt={{ md: 4, sm: 1.5, xs: 5 }}
//                                 ml={{ xs: '2px', sm: '6px', md: '0px' }}
//                                 xs={12}
//                                 sm={contextData.compareList?.length === 2 ? 5.4 : 6}
//                                 md={contextData.compareList?.length === 2 ? 5.4 : 3.9}
//                                 sx={compareStyles}
//                                 item
//                                 spacing={1}
//                             >
//                                 <Grid component="div" className="box-wrapper">
//                                     <img
//                                         onClick={() =>
//                                             contextData.handleCompareProduct(item, 'remove', 'compare', type, ids)
//                                         }
//                                         className="compare-close-icon"
//                                         width={30}
//                                         height={30}
//                                         alt="close image"
//                                         src="/assets/Close_round.svg"
//                                     />
//                                     <Grid container xs={12} sm={12} md={12}>
//                                         <Grid className="glb-image-container" xs={12} sm={12} md={contextData.compareList?.length === 2 ? 9.6 : 8.6}>
//                                             <Box className="glb-image">
//                                                 {images?.glb ? (
//                                                     <View3D
//                                                         autoplay={true}
//                                                         scrollable={true}
//                                                         tag="div"
//                                                         src={images?.glb}
//                                                         poster="/public/vercel.svg"
//                                                         style={{ height: '300px', width: '300px' }}
//                                                     />
//                                                 ) : (
//                                                     <img
//                                                         onClick={() => handleClickOpenModal(images)}
//                                                         width={300}
//                                                         height={300}
//                                                         className="main-image"
//                                                         src={mainImage}
//                                                         alt="Main Image"
//                                                         style={{ borderRadius: '8px', objectFit: 'cover' }}
//                                                     />
//                                                 )}
//                                             </Box>
//                                         </Grid>
//                                         <Grid
//                                             className="image-scroll-container"
//                                             mt={{ xs: 3, md: 0, sm: 3 }}
//                                             justifyContent={{ xs: 'space-between' }}
//                                             container
//                                             xs={12}
//                                             sm={12}
//                                             md={2}
//                                         >
//                                             <Grid className="scroll-content" xs={9} sm={9}>
//                                                 {images?.thumbImage.slice(0, 3).map((img: any, idx: number) => (
//                                                     <Grid
//                                                         key={idx}
//                                                         xs={6}
//                                                         sm={6}
//                                                         style={{
//                                                             position: 'relative',
//                                                             flexShrink: 0,
//                                                             margin: '0 8px',
//                                                             padding: '1.2px',
//                                                         }}
//                                                     >
//                                                         <img
//                                                             onClick={() => handleClickOpenModal(images)}
//                                                             width={100}
//                                                             height={100}
//                                                             className="slide-image"
//                                                             src={img}
//                                                             alt={`Image ${idx}`}
//                                                         />
//                                                         {idx === 2 && (
//                                                             <Grid
//                                                                 xs={4}
//                                                                 sm={4}
//                                                                 onClick={() => handleClickOpenModal(images)}
//                                                                 className="hover-container"
//                                                             >
//                                                                 <Typography sx={{ marginTop: '-8px' }}>
//                                                                     +{images?.thumbImage.length - 3}
//                                                                 </Typography>
//                                                             </Grid>
//                                                         )}
//                                                     </Grid>
//                                                 ))}
//                                             </Grid>
//                                         </Grid>
//                                     </Grid>

//                                     <Typography
//                                         onClick={() => handleClickOpenModal(images)}
//                                         className="images-content"
//                                         variant="subtitle1"
//                                         gutterBottom
//                                     >
//                                         Click to open expanded view
//                                     </Typography>
//                                     <br />

//                                     {/* Render Fields Based on Category */}
//                                     <Grid container spacing={1}>
//                                         {item?.category === 'rpt' &&
//                                             (<Grid container spacing={2}>
//                                                 <Grid item xs={6} md={8} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Product Code
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.productCode || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Product Availability
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.productAvailability || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Product Type
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.productType || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Best Suited For
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.bestSuite || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Moulding Process
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.mouldingProcess || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Material
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.material || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Container Type
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.containerType || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Shape
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.shapes || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         SKU Range
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.skuRange || '-'} {item?.skuUnit}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Over Fill Capacity
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.overFillCapacity || '-'} ML
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6} >
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Weight
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.weight || '-'} GM
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6} >
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Vendor Name
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.vendorName || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6} >
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Price
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.price || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6} >
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Decoration
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.decoration || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6} >
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         MOQ
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.moq || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6} >
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Colour
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.colour || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={12} md={12} sm={12}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Product Description
//                                                     </Typography>
//                                                     <Typography variant="body2" gutterBottom>
//                                                         {item?.productDescription || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                             </Grid>)}
//                                         {item?.category === 'gmt' &&
//                                             (<Grid container spacing={2}>
//                                                 <Grid item xs={6} md={8} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Product Code
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.productCode || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Product Availability
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.productAvailability || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Product Type
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.productType || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Best Suited For
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.bestSuite || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Tube Type
//                                                     </Typography>

//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.tubeType || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Material
//                                                     </Typography>

//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.material || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         MOQ
//                                                     </Typography>

//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.moq || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Shape
//                                                     </Typography>

//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.shapes || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         SKU Range
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.skuRange || '-'} {item?.skuUnit}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Over Fill Capacity
//                                                     </Typography>

//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.overFillCapacity || '-'} ML
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6} >
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Weight
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.weight || '-'} GM
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6} >
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Vendor Name
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.vendorName || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6} >
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Price
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.price || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6} >
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Decoration
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.decoration || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6} >
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Colour
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.colour || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={12} md={12} sm={12}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Product Description
//                                                     </Typography>
//                                                     <Typography variant="body2" gutterBottom>
//                                                         {item?.productDescription || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                             </Grid>)}

//                                         {item?.category === 'flexy' &&
//                                             (<Grid container spacing={2}>
//                                                 <Grid item xs={6} md={8} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Product Code
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.productCode || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Product Availability
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.productAvailability || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Product Type
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.productType || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Best Suited For
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.bestSuite || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Pouch Type
//                                                     </Typography>

//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.pouchType || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Material(Structure)
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.material || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Opening Mechanism
//                                                     </Typography>

//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.openingMechanism || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Shape
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.shapes || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         SKU Range
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.skuRange || '-'} {item?.skuUnit}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Pouch Fill Capacity
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.pouchFillCapacity || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6} >
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Spout Type & Size
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.spoutTypeSize || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6} >
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Price
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.price || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6} >
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         MOQ
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.moq || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={12} md={12} sm={12}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Product Description
//                                                     </Typography>
//                                                     <Typography variant="body2" gutterBottom>
//                                                         {item?.productDescription || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                             </Grid>)}

//                                         {item?.category === 'impression' &&
//                                             (<Grid container spacing={2}>
//                                                 <Grid item xs={6} md={8} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Product Code
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.productCode || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Product Availability
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.productAvailability || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Product Type
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.productType || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Carton Style
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.cartonStyle || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Material
//                                                     </Typography>

//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.material || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Shape
//                                                     </Typography>

//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.shapes || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6} >
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Vendor Name
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.vendorName || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6} >
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Price
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.price || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={8} sm={6} >
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Decoration
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.decoration || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={6} md={4} sm={6} >
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         MOQ
//                                                     </Typography>
//                                                     <Typography variant="subtitle1" gutterBottom>
//                                                         {item?.moq || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                                 <Grid item xs={12} md={12} sm={12}>
//                                                     <Typography className='vendor-text' variant="h6" gutterBottom>
//                                                         Product Description
//                                                     </Typography>
//                                                     <Typography variant="body2" gutterBottom>
//                                                         {item?.productDescription || '-'}
//                                                     </Typography>
//                                                 </Grid>
//                                             </Grid>)}
//                                     </Grid>
//                                 </Grid>
//                             </Grid>
//                         );
//                     })}

//                     {contextData.compareList?.length >= 1 && contextData.compareList?.length !== 3 && (
//                         <Grid
//                             mt={{ md: 4, sm: 1.5, xs: 5 }}
//                             xs={3}
//                             sm={0.9}
//                             md={0.9}
//                             sx={compareStyles}
//                             item
//                             spacing={1}
//                         >
//                             <Paper onClick={() => router('/productView')} className="empty-box-wrapper" variant="outlined">
//                                 <Grid className="avatar-icon" container>
//                                     <img src="/assets/add_square.png" width={30} height={30} alt="add image" />
//                                 </Grid>
//                             </Paper>
//                         </Grid>
//                     )}
//                 </Box>
//             </Grid>
//         </>
//     );
// };

// export default CompareProduct;


import { Typography, Box, Grid, Paper } from '@mui/material';
import ProductDetailsHead from '../product-detail-component/product-head-button';
import { compareStyles } from '../../styles/compare.style';
import { useNavigate } from "react-router-dom";
import View3D from "@egjs/react-view3d";
import "@egjs/react-view3d/css/view3d-bundle.min.css";
import Context from '../../hooks/context';
import { imageHandle } from '../../services/image-handle.service';
import { useContext } from 'react';

const compareContainer = {
    padding: { md: '20px 0px 0px 25px', xs: '10px 10px 30px 10px', sm: '35px 0px 30px 10px' },
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'scroll',
};

const compareBox = {
    position: 'relative',
    flex: '0 0 auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: { md: "140px", xs: '130px', sm: '170px' },
};

const subHeader = {
    width: '100%',
    position: 'fixed',
    zIndex: 2,
    background: 'white',
};

const CompareProduct = ({ handleClickCompare, handleClickOpenModal, type, ids }: any) => {
    const contextData: any = useContext(Context);
    const router = useNavigate();

    return (
        <>
            {/* Header Section */}
            <Grid sx={compareContainer} container spacing={1}>
                <Grid sx={subHeader} mt={{ md: 11, xs: 10, sm: 11 }}>
                    <ProductDetailsHead
                        page="compare"
                        data={contextData.compareList}
                        handleClickCompare={handleClickCompare}
                    />
                </Grid>

                {/* Main Content Section */}
                <Box id="box-wrapper" p={{ md: 3, sm: 2, xs: 1 }} mt={{ md: 1, sm: 2, xs: 2 }} sx={compareBox}>
                    {contextData.compareList?.map((item: any, index: number) => {
                        const images = imageHandle(item.imageURLs);
                        const mainImage = images?.glb || images?.thumbImage[1] || images?.thumbImage[0] || "/assets/no-image.png";

                        return (
                            <Grid
                                key={index}
                                mt={{ md: 4, sm: 1.5, xs: 5 }}
                                ml={{ xs: '2px', sm: '6px', md: '0px' }}
                                xs={12}
                                sm={contextData.compareList?.length === 2 ? 5.4 : 6}
                                md={contextData.compareList?.length === 2 ? 5.4 : 3.9}
                                sx={compareStyles}
                                item
                                spacing={1}
                            >
                                <Grid component="div" className="box-wrapper">
                                    {/* Close Button */}
                                    <img
                                        onClick={() =>
                                            contextData.handleCompareProduct(item, 'remove', 'compare', type, ids)
                                        }
                                        className="compare-close-icon"
                                        width={30}
                                        height={30}
                                        alt="Remove Item"
                                        src="/assets/Close_round.svg"
                                    />

                                    {/* Main Image Section */}
                                    <Grid container xs={12} sm={12} md={12}>
                                        <Grid className="glb-image-container" xs={12} sm={12} md={contextData.compareList?.length === 2 ? 9.6 : 8.6}>
                                            <Box className="glb-image">
                                                {images?.glb ? (
                                                    <View3D
                                                        autoplay={true}
                                                        scrollable={true}
                                                        tag="div"
                                                        src={images?.glb}
                                                        poster="/assets/no-image.png"
                                                        style={{ height: '300px', width: '300px' }}
                                                    />
                                                ) : (
                                                    <img
                                                        onClick={() => handleClickOpenModal(images)}
                                                        className="main-image"
                                                        src={mainImage}
                                                        alt="Main Product"
                                                        style={{ borderRadius: '8px', objectFit: 'cover', height: '45vh', width: '300px' }}
                                                    />
                                                )}
                                            </Box>
                                        </Grid>

                                        {/* Thumbnail Section */}
                                        <Grid
                                            className="image-scroll-container"
                                            mt={{ xs: 3, md: 0, sm: 3 }}
                                            justifyContent="space-between"
                                            container
                                            xs={12}
                                            sm={12}
                                            md={2}
                                        >
                                            <Grid className="scroll-content" xs={9} sm={9}>
                                                {images?.thumbImage.slice(0, 3).map((img: any, idx: number) => (
                                                    <Grid
                                                        key={idx}
                                                        xs={6}
                                                        sm={6}
                                                        style={{
                                                            position: 'relative',
                                                            flexShrink: 0,
                                                            margin: '0 8px',
                                                            padding: '1.2px',
                                                        }}
                                                    >
                                                        <img
                                                            onClick={() => handleClickOpenModal(images)}
                                                            width={100}
                                                            height={100}
                                                            className="slide-image"
                                                            src={img}
                                                            alt={`Thumbnail ${idx}`}
                                                        />
                                                        {idx === 2 && (
                                                            <Grid
                                                                xs={4}
                                                                sm={4}
                                                                onClick={() => handleClickOpenModal(images)}
                                                                className="hover-container"
                                                            >
                                                                <Typography sx={{ marginTop: '-8px' }}>
                                                                    +{images?.thumbImage.length - 3}
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* Click to Open View */}
                                    <Typography
                                        onClick={() => handleClickOpenModal(images)}
                                        className="images-content"
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        Click to open expanded view
                                    </Typography>
                                    <br />

                                    {/* Fields Section Based on Category */}
                                    <Grid container spacing={1}>
                                        {item?.category === 'rpt' &&
                                            (<Grid container spacing={2}>
                                                <Grid item xs={6} md={8} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Product Code / Name
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.productCode || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Product Availability
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.productAvailability || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Product Type
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.productType || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Best Suited For
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.bestSuite || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Moulding Process
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.mouldingProcess || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Material
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.material || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Container Type
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.containerType || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Shape
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.shapes || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        SKU Range
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.skuRange || '-'} {item?.skuUnit}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Over Fill Capacity
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.overFillCapacity || '-'} ML
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6} >
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Weight Range
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.weight || '-'} GM
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6} >
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Vendor Name
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.vendorName || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6} >
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Price
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.price || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6} >
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Decoration
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.decoration || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6} >
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        MOQ
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.moq || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6} >
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Colour
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.colour || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6} >
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Neck Size
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.neckSize || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Closure Suitability
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.closureSuitability || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={12} sm={12}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Product Description
                                                    </Typography>
                                                    <Typography variant="body2" gutterBottom>
                                                        {item?.productDescription || '-'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>)}
                                        {item?.category === 'gmt' &&
                                            (<Grid container spacing={2}>
                                                <Grid item xs={6} md={8} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Product Code / Name
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.productCode || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Product Availability
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.productAvailability || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Product Type
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.productType || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Best Suited For
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.bestSuite || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Tube Type
                                                    </Typography>

                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.tubeType || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Material
                                                    </Typography>

                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.material || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        MOQ
                                                    </Typography>

                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.moq || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Shape
                                                    </Typography>

                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.shapes || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        SKU Range
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.skuRange || '-'} {item?.skuUnit}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Over Fill Capacity
                                                    </Typography>

                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.overFillCapacity || '-'} ML
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6} >
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Weight
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.weight || '-'} GM
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6} >
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Vendor Name
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.vendorName || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6} >
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Price
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.price || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6} >
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Decoration
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.decoration || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6} >
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Colour
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.colour || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Closure Suitability
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.closureSuitability || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Orifice Available
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.orificeAvailable || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={12} sm={12}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Product Description
                                                    </Typography>
                                                    <Typography variant="body2" gutterBottom>
                                                        {item?.productDescription || '-'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>)}

                                        {item?.category === 'flexy' &&
                                            (<Grid container spacing={2}>
                                                <Grid item xs={6} md={8} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Product Code / Name
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.productCode || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Product Availability
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.productAvailability || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Product Type
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.productType || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Best Suited For
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.bestSuite || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Pouch Type
                                                    </Typography>

                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.pouchType || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Material(Structure)
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.material || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Opening Mechanism
                                                    </Typography>

                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.openingMechanism || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Shape
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.shapes || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        SKU Range
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.skuRange || '-'} {item?.skuUnit}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Pouch Fill Capacity
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.pouchFillCapacity || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6} >
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Spout Type & Size
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.spoutTypeSize || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6} >
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Price
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.price || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6} >
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        MOQ
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.moq || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={12} sm={12}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Product Description
                                                    </Typography>
                                                    <Typography variant="body2" gutterBottom>
                                                        {item?.productDescription || '-'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>)}

                                        {item?.category === 'impression' &&
                                            (<Grid container spacing={2}>
                                                <Grid item xs={6} md={8} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Product Code / Name
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.productCode || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Product Availability
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.productAvailability || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Product Type
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.productType || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Carton Style
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.cartonStyle || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Material
                                                    </Typography>

                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.material || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Shape
                                                    </Typography>

                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.shapes || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6} >
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Vendor Name
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.vendorName || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6} >
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Price
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.price || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={8} sm={6} >
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Decoration
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.decoration || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4} sm={6} >
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        MOQ
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {item?.moq || '-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={12} sm={12}>
                                                    <Typography className='vendor-text' variant="h6" gutterBottom>
                                                        Product Description
                                                    </Typography>
                                                    <Typography variant="body2" gutterBottom>
                                                        {item?.productDescription || '-'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}

                    {/* Add Product Button */}
                    {contextData.compareList?.length >= 1 && contextData.compareList?.length !== 3 && (
                        <Grid
                            mt={{ md: 4, sm: 1.5, xs: 5 }}
                            xs={3}
                            sm={0.9}
                            md={0.9}
                            sx={compareStyles}
                            item
                            spacing={1}
                        >
                            <Paper onClick={() => router('/productView')} className="empty-box-wrapper" variant="outlined">
                                <Grid className="avatar-icon" container>
                                    <img src="/assets/add_square.png" width={30} height={30} alt="Add Product" />
                                </Grid>
                            </Paper>
                        </Grid>
                    )}
                </Box>
            </Grid>
        </>
    );
};

export default CompareProduct;
