import { useNavigate } from "react-router-dom";
import { Box, Grid, Button, Typography, Snackbar, Slide, SlideProps, SnackbarContent, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, FormGroup, Drawer, Checkbox, Stack, TextField, InputAdornment, Tooltip, Fab,TableSortLabel, CircularProgress } from "@mui/material";
import SuccessIcon from '../../icons/successIcon.svg';
import CloseIcon from '../../icons/closeIcon.svg';
import CrossIcon from '../../icons/crossIcon.png';
import { useEffect, useRef, useState } from "react";
import UploadIcon from '../../icons/uploadIcon.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from "axios";
import SearchIcon from '../../icons/SearchIcon.svg';
import "./BarcodeRequest.css";
import qs from 'qs';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BarcodeTable from "./BarcodeTable";
import { useSelector } from 'react-redux'; // Import useSelector to get state from Redux
import { RootState } from '../../redux/store/store'; // Import RootState from the store

const BarcodeList = () => {

    const barcodeType = useSelector((state: RootState) => state.barcode.barcodeType); // Access barcodeType from Redux store

    const router = useNavigate();
    const [barcodeSnackbarOpen, setBarcodeSnackbarOpen] = useState(false);
    const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    const [selectedBarcodeStatus, setSelectedBarcodeStatus] = useState<string[]>([]);
    const [rows, setRows] = useState<BarcodeItem[]>([]);
    const [filteredData, setFilteredData] = useState<BarcodeItem[]>([]);
    const [brandData, setBrandData] = useState<DataOption[]>([]);
    const [productData, setProductData] = useState<DataOption[]>([]);
    const [selectedBrand, setSelectedBrand] = useState<string[]>([]);
    const [showFilteredData, setShowFilteredData] = useState(false);
    const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [messageType, setMessageType] = useState<'success' | 'error'>('success');

    const [loading, setLoading] = useState(false);
    const [brandPage, setBrandPage] = useState(0);
    const [hasMoreBrandData, setHasMoreBrandData] = useState(true);
    const [showGoToTop, setShowGoToTop] = useState(false);

    const [filtersApplied, setFiltersApplied] = useState(false);  // To trigger re-fetching of data

    interface BarcodeItem {
        id: any;
        sno: number,
        barcode: any,
        brandName: any,
        productName: any,
        description: any,
        sku: any,
        requestedDate: any,
        assignedDate?: any,
        employeeName: any,
        empNo: any,
        assignee: any
    }
    interface DataOption {
        value: string;
        label: string;
    }

    useEffect(() => {
        // const debouncedScroll = debounce(handleScroll, 300);
        // window.addEventListener("scroll", debouncedScroll);
        // Show "Go to Top" button after scrolling down
        const handleShowTopButton = () => {
            setShowGoToTop(window.scrollY > 500); // Adjust scroll position as needed
        };
        window.addEventListener("scroll", handleShowTopButton);

        return () => {
            // window.removeEventListener("scroll", debouncedScroll);
            window.removeEventListener("scroll", handleShowTopButton);
        };
    }, [showFilteredData]);

    const handleGoToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

   // Fetch brand data on component mount and page change
   useEffect(() => {
    if (hasMoreBrandData) {
        getBrandData(brandPage);
    }
}, [brandPage, hasMoreBrandData]); // Only fetch when brandPage or hasMoreBrandData changes


     // Scroll event handler for infinite scrolling
     const handleBrandScroll = async (event: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        const buffer = 100;

        // Trigger loading when near bottom and ensure it's not already loading
        if (scrollHeight - scrollTop <= clientHeight + buffer && !loading && hasMoreBrandData) {
            setLoading(true);
            // Increment the page number only after data is fetched
            setBrandPage((prev) => prev + 1);
        }
    };

    const handleCloseBarcodeSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setBarcodeSnackbarOpen(false);
    };
    type Anchor = 'right';

    const SlideTransition = (props: SlideProps) => {
        return <Slide {...props} direction="left" />;
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, type: 'brand' | 'product') => {
        const value = event.target.value;

        if (type === 'brand') {
            setSelectedBrands((prev) =>
                prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
            );
        } else if (type === 'product') {
            setSelectedProducts((prev) =>
                prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
            );
        }
    };

    useEffect(() => {
        if (selectedBrands.length > 0) {
            getProductData(selectedBrands);
        } else {
            setProductData([]);
        }
    }, [selectedBrands]);

    useEffect(() => {
        if (selectedBrands.length > 0) {
            getProductData(selectedBrands);
            setSelectedProducts([]);
        } else {
            setProductData([]);
            setSelectedProducts([]);
        }
    }, [selectedBrands]);

    const list = (anchor: Anchor) => (
        <Box sx={{ width: 350, overflowY: 'scroll' }} role="presentation">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1.5vh' }}>
                <Typography variant="h6">Filters</Typography>
                <Box>
                    <span style={{ color: 'red' }}>Close</span>
                    <img onClick={() => setFilterDrawerOpen(false)} src={CloseIcon} style={{ cursor: 'pointer' }} alt="close" />
                </Box>
            </Box>
            <Grid item xs={3} style={{ marginInline: '3vh' }}>
            </Grid>
            <Accordion sx={{ outline: 'none', boxShadow: 'none', marginBlockStart: '0.8vh' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography variant="body1">Brand</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ marginBlockStart: '-1vh', marginBlockEnd: '-3vh' }}>
                    <Box
                        sx={{
                            maxHeight: '120px',
                            overflowY: 'auto',
                        }}
                        onScroll={handleBrandScroll}
                    >
                        <FormGroup>
                            {brandData.map(option => (
                                <FormControlLabel
                                    sx={{
                                        marginBlock: '-3.5px',
                                        whiteSpace: 'normal',       // Allows wrapping onto multiple lines
                                        wordBreak: 'break-word',    // Breaks words if they are too long
                                    }}
                                    key={option.value}
                                    control={
                                        <Checkbox
                                            checked={selectedBrands.includes(option.value)}
                                            onChange={(e) => handleCheckboxChange(e, 'brand')}
                                            value={option.value}
                                        />
                                    }
                                    label={option.label}
                                />
                            ))}
                        </FormGroup>
                        {loading && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1vh' }}>
                                <Typography variant="body2">Loading more...</Typography>
                            </Box>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ outline: 'none', boxShadow: 'none' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography variant="body1">Product</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ marginBlockStart: '-1vh', marginBlockEnd: '-3vh' }}>
                    <Box
                        sx={{
                            maxHeight: '100px',
                            overflowY: 'auto',
                        }}
                    >
                        <FormGroup>
                            {productData.map(option => (
                                <FormControlLabel
                                    sx={{ marginBlock: '-7px' }}
                                    key={option.value}
                                    control={
                                        <Checkbox
                                            checked={selectedProducts.includes(option.value)}
                                            onChange={(e) => handleCheckboxChange(e, 'product')}
                                            value={option.value}
                                        />
                                    }
                                    label={option.label}
                                />
                            ))}
                        </FormGroup>
                        {loading && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1vh' }}>
                                <Typography variant="body2">Loading more...</Typography>
                            </Box>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ outline: 'none', boxShadow: 'none' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography variant="body1">Barcode</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ marginBlockStart: '-1vh', marginBlockEnd: '-3vh' }}>
                    <FormGroup>
                        {['Assigned', 'UnAssigned'].map(option => (
                            <FormControlLabel
                                sx={{ marginBlock: '-7px' }}
                                key={option}
                                control={
                                    <Checkbox
                                        checked={selectedBarcodeStatus.includes(option)}
                                        onChange={handleBarcodeStatusChange}
                                        value={option}
                                    />
                                }
                                label={option}
                            />
                        ))}
                    </FormGroup>
                </AccordionDetails>
            </Accordion>
            <Stack spacing={2} direction="row" style={{ marginInlineStart: '25vh', marginBlockStart: '5vh' }}>
                <Button variant="outlined" sx={{ textTransform: 'none' }} onClick={handleClearFilters}>Clear</Button>
                <Button variant="contained" sx={{ textTransform: 'none' }} onClick={handleApplyClick}>Apply</Button>
            </Stack>
        </Box>
    );

    const handleBarcodeStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSelectedBarcodeStatus((prev) =>
            prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
        );
    };

    // const getFilteredData = async (brandNames: string[], productNames: string[], statuses: string[]) => {
    //     const token = localStorage.getItem('AccessToken');
    //     try {
    //         // Use GET request with parameters in the URL
    //         const filteredData = await axios.get(
    //             `${process.env.REACT_APP_BASE_URL}/barcodes/admin/filterOfBarcodes`,
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`
    //                 },
    //                 params: {
    //                     brandNames,
    //                     productNames,
    //                     statuses
    //                 },
    //                 paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
    //             }
    //         );
    //         setFilteredData(filteredData.data);
    //         setShowFilteredData(true);
    //         setFilterDrawerOpen(false);
    //     } catch (error) {
    //         console.error('Error fetching filtered data:', error);
    //     }
    // };
    
    // Fetch brand data from API
   
   
    const getBrandData = async (page: number) => {
        const token = localStorage.getItem('AccessToken');
        try {
            let apiUrl = '';

            if (barcodeType === 'ean') {
              apiUrl = `${process.env.REACT_APP_BASE_URL}/barcodes/admin/brandsOfBarcodes`;
            } else if (barcodeType === 'upc') {
              apiUrl = `${process.env.REACT_APP_BASE_URL}/barcodesInternational/admin/brandsOfBarcodesInternational`;
            } else {
              console.error('Invalid barcode type');
              return;
            }

            const response = await axios.get(apiUrl, {
                headers: { Authorization: `Bearer ${token}` },
                params: { page }
            });
            const formattedData = response.data.map((item: string) => ({ value: item, label: item }));
            setBrandData((prev) => [...prev, ...formattedData]); // Append new data

            if (formattedData.length === 0) {
                setHasMoreBrandData(false); // No more data to load
            }
        } catch (error) {
            console.error('Error fetching brand data:', error);
        } finally {
            setLoading(false); // Ensure loading is set to false after request finishes
        }
    };

    const getProductData = async (brandNames: string[], page = 0) => {
        const token = localStorage.getItem('AccessToken');
        try {
            let apiUrl = '';

            if (barcodeType === 'ean') {
              apiUrl = `${process.env.REACT_APP_BASE_URL}/barcodes/admin/productsOfBarcodes`;
            } else if (barcodeType === 'upc') {
              apiUrl = `${process.env.REACT_APP_BASE_URL}/barcodesInternational/admin/productsOfBarcodesInternational`;
            } else {
              console.error('Invalid barcode type');
              return;
            }

            const responses = await Promise.all(
                brandNames.map(brandName =>
                    axios.get(apiUrl, {
                        headers: { Authorization: `Bearer ${token}` },
                        params: { brandName }
                    })
                )
            );
            const formattedData = responses.flatMap(response =>
                response.data.map((item: string) => ({ value: item, label: item }))
            );
            setProductData(formattedData);
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    };

    useEffect(() => {
        if (selectedBrand.length > 0) {
            getProductData(selectedBrand);
        } else {
            setProductData([]);
        }
    }, [selectedBrand]);

    // const handleApplyClick = () => {
    //     getFilteredData(selectedBrands, selectedProducts, selectedBarcodeStatus);
    // };

    const handleApplyClick = () => {
        // On button click, set filtersApplied to true to trigger child component to reload data
        setFiltersApplied(true);
        setFilterDrawerOpen(false);
    };

    const [page, setPage] = useState(0);
    const [moreData, setMoreData] = useState(true);
    const [size] = useState(100); // Size of the page

    const loadMoreBarcodesHandler = async (page: number, isMoreData: boolean, searchText: string, selectedBrandsList: string[], selectedProductsList: string[], selectedBarcodeStatus: string[], columns: string, directions: string) => {
        if (loading || !isMoreData) {
            return; // Prevent multiple fetches at once
        }

        setLoading(true); // Set loading state to true
        const token = localStorage.getItem('AccessToken');
        try {
            let apiUrl = '';

            if (barcodeType === 'ean') {
                apiUrl = `${process.env.REACT_APP_BASE_URL}/barcodes/admin/filterOfBarcodes`;
            } else if (barcodeType === 'upc') {
                apiUrl = `${process.env.REACT_APP_BASE_URL}/barcodesInternational/admin/filterOfBarcodesInternational`;
            } else {
                console.error('Invalid barcode type');
                return;
            }

            let url = `${apiUrl}?page=${page}&size=${size}`;
            const queryParams: string[] = [];

            // Conditionally build the query parameters for search and filters
            if (searchText.trim()) {
                queryParams.push(`searchText=${encodeURIComponent(searchText)}`);
            }

            if (selectedBrandsList.length > 0) {
                selectedBrandsList.forEach((brand) => {
                    queryParams.push(`brandNames=${encodeURIComponent(brand)}`);
                });
            }

            if (selectedProductsList.length > 0) {
                selectedProductsList.forEach((product) => {
                    queryParams.push(`productNames=${encodeURIComponent(product)}`);
                });
            }

            // Add other filters (e.g., barcode status)
            if (selectedBarcodeStatus.length > 0) {
                selectedBarcodeStatus.forEach((status) => {
                    queryParams.push(`statuses=${encodeURIComponent(status)}`);
                });
            }

            // Add sorting parameters
            queryParams.push(`sortBy=${encodeURIComponent(columns)}`);
            queryParams.push(`sortDirection=${encodeURIComponent(directions)}`);

            // Add the query parameters to the URL if any are present
            if (queryParams.length > 0) {
                url += `&${queryParams.join('&')}`;
            }

            const barcodeList = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const newData: BarcodeItem[] = barcodeList?.data || [];

            if (newData.length < size) {
                setMoreData(false); // No more data to load
            }

            // If there are new data, update rows and increment page
            if (newData.length > 0) {
                setRows((prevData) => [...prevData,...newData]);
               
                setPage((prevPage) => prevPage + 1); // Increment page number
            }
        } catch (err) {
            console.error('Error fetching barcode data:', err);
        } finally {
            setLoading(false); // Set loading state to false
            setFiltersApplied(false);
        }
    };

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;
        const file = fileInput.files?.[0];
        if (file) {
            const formData = new FormData();
            formData.append("file", file);
            const token = localStorage.getItem('AccessToken');
            try {
                let apiUrl = '';

                if (barcodeType === 'ean') {
                  apiUrl = `${process.env.REACT_APP_BASE_URL}/barcodes/admin/upload`;
                } else if (barcodeType === 'upc') {
                  apiUrl = `${process.env.REACT_APP_BASE_URL}/barcodesInternational/admin/uploadInternational`;
                } else {
                  console.error('Invalid barcode type');
                  return;
                }

                const response = await axios.post(apiUrl, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${token}`,
                    }
                });

                // Safe appending of barcodes, fallback to an empty array if undefined
                setRows((prevData) => [...prevData, ...(response?.data?.barcodes || [])]);       

                setMessageType('success');
                setSnackbarMessage('File uploaded successfully');

                // After the file upload is successful, call the loadMoreBarcodes function to fetch data
                const searchText = ''; // Customize this as needed
                const selectedBrandsList: string[] = []; // Customize as needed
                const selectedProductsList: string[] = []; // Customize as needed
                const selectedBarcodeStatus: string[] = []; // Customize as needed
                const columns = 'requestedDate'; // Customize as needed
                const directions = 'asc'; // Customize as needed

                // Call the child function to load more barcodes
                // loadMoreBarcodesHandler(0, true, searchText, selectedBrandsList, selectedProductsList, selectedBarcodeStatus, columns, directions);
      

            } catch (error: unknown) {
                let errorMessage = 'An unexpected error occurred';
                if (axios.isAxiosError(error) && error.response) {
                    errorMessage = error.response.data?.message || errorMessage;
                }
                setMessageType('error');
                setSnackbarMessage(errorMessage);
            } finally {
                setBarcodeSnackbarOpen(true);
                fileInput.value = '';
            }
        }
    };


    const handleClearFilters = () => {
        setSelectedBrands([]);
        setSelectedProducts([]);
        setSelectedBarcodeStatus([]);
        setSelectedBrand([]);
        setFilteredData([]);
        setRows([]);
        setShowFilteredData(false);
        setFiltersApplied(true);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <Grid item xs={8} sx={{ marginTop: '80px' }}>
            <Box>
                <Box className="back-icon" sx={{ marginBlockEnd: '1vh', display: 'flex', alignItems: 'center' }}>
                    <img className="icon" onClick={() => router('/barcodeRequest')} alt="back icon" style={{ marginLeft: '4vh', height: '24px', width: '24px', cursor: 'pointer' }} src="/assets/icon/back_arrow.svg" />
                    <Typography onClick={() => router('/barcodeRequest')} sx={{ marginLeft: '0%', fontSize: '17px', cursor: 'pointer' }}>Back</Typography>
                </Box>
                <Grid container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', marginBlockEnd: '4vh' }}>
                    <Grid sx={{ flexBasis: "60%", marginLeft: '5vh' }} item xs={5}>
                        <Typography variant="h6">Barcode Database</Typography>
                    </Grid>
                    <Grid sx={{ flexBasis: "40%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '3vh', marginInlineEnd: '5vh', alignItems: 'center' }} item xs={4}>
                        <Grid item xs={6}>
                            <TextField
                                id="fsrch"
                                name="fsrch"
                                placeholder="Search"
                                size="small"
                                style={{ backgroundColor: '#f0f0f0', borderRadius: '2vh' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={SearchIcon} alt="search" style={{ marginInlineStart: '-1vh' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: 'none',
                                        },
                                    }
                                }}
                                onChange={handleSearchChange}
                            />
                        </Grid>
                        <img className="icon" onClick={() => setFilterDrawerOpen(true)} alt="filter icon" style={{ marginLeft: '-8vh', height: '24px', width: '24px', cursor: 'pointer' }} src="/assets/filterIcon.svg" />
                        <input
                            type="file"
                            accept=".xlsx,.csv"
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            onChange={handleFileUpload}
                        />
                        <Button sx={{ borderRadius: '1vh', textTransform: 'none', marginInlineStart: '-8vh', marginRight: '1vh' }} variant="outlined" onClick={handleUploadClick} endIcon={<img src={UploadIcon} alt="UploadIcon" />}> Upload</Button>
                    </Grid>
                </Grid>

                {/* Table Sorting */}
                <BarcodeTable 
                    searchTerm={searchTerm} 
                    showFilteredData={showFilteredData} 
                    selectedBrands={selectedBrands}
                    selectedProducts={selectedProducts}
                    selectedBarcodeStatus={selectedBarcodeStatus}
                    filtersApplied={filtersApplied}
                    loadMoreBarcodes={loadMoreBarcodesHandler}
                    page={page}
                    moreData={moreData}
                    rows={rows}
                    setPage={setPage} // You can pass the setter functions as props as well
                    setMoreData={setMoreData}
                    setRows={setRows}
                 />
            </Box>
            {/* <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} TransitionComponent={SlideTransition}>
                <SnackbarContent {...snackbarContentProps} />
            </Snackbar> */}
            <Snackbar open={barcodeSnackbarOpen} autoHideDuration={3000} onClose={handleCloseBarcodeSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{
                    '& .MuiSnackbarContent-root': {
                        backgroundColor: 'white',
                        color: messageType === 'success' ? 'green' : 'red',
                        borderRadius: '8px',
                        padding: '1.5vh',
                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
                    }
                }}
                TransitionComponent={SlideTransition}>
                <SnackbarContent message={(
                    <div style={{ display: 'flex', alignItems: 'center', background: 'white' }}>
                        <img src={messageType === 'success' ? SuccessIcon : CrossIcon} alt="status icon" />
                        <span style={{ marginInline: '1vh' }}>
                            {snackbarMessage}
                        </span>
                        <img src={CloseIcon} onClick={handleCloseBarcodeSnackbar} style={{ cursor: 'pointer' }} alt="close" />
                    </div>
                )}
                />
            </Snackbar>
            <Drawer

                anchor='right'
                open={filterDrawerOpen}
                onClose={() => setFilterDrawerOpen(false)}
            >
                {list('right')}
            </Drawer>

            {showGoToTop && (
                <Fab
                    color="primary"
                    size="small"
                    aria-label="go to top"
                    onClick={handleGoToTop}
                    sx={{
                        position: 'fixed',
                        bottom: '2rem',
                        right: '2rem',
                        zIndex: 1000,
                    }}
                >
                    <KeyboardArrowUpIcon />
                </Fab>
            )}
        </Grid >
    );
};

export default BarcodeList;
