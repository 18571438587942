// barcodeSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BarcodeState {
  barcodeType: 'ean' | 'upc' | 'packaging';
}

const initialState: BarcodeState = {
  barcodeType: localStorage.getItem('barcodeType') as 'ean' | 'upc' | 'packaging', // Initialize from localStorage
};

const barcodeSlice = createSlice({
  name: 'barcode',
  initialState,
  reducers: {
    setBarcodeType: (state, action: PayloadAction<'ean' | 'upc' | 'packaging'>) => {
      state.barcodeType = action.payload;
      // Save barcodeType to localStorage whenever it is updated
      localStorage.setItem('barcodeType', action.payload);
    },
  },
});

export const { setBarcodeType } = barcodeSlice.actions;
export default barcodeSlice.reducer;
