import { memo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { Badge, Box, Button, CardContent, Grid, Paper, Typography } from "@mui/material";
import { useCompareStyles } from "../../styles/index-component.style";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { mediaQuerySm, mediaQueryXs } from "../../services/media-query-xs";
import Context from "../../hooks/context";
import { imageHandle } from "../../services/image-handle.service";

const BottomCompareComponent = ({ type }: any) => {
    const router = useNavigate();
    const matches: any = mediaQueryXs();
    const tabView: any = mediaQuerySm();
    const contextData: any = useContext(Context);
    const urls = contextData?.compareList?.length === 2 ? `/compare/${type}?id=${contextData?.compareList[0]?.productType + '_' + contextData?.compareList[0]?.id}&id=${contextData?.compareList[1]?.productType + '_' + contextData?.compareList[1]?.id}` : `/compare/${type}?id=${contextData?.compareList[0]?.productType + '_' + contextData?.compareList[0]?.id}&id=${contextData?.compareList[1]?.productType + '_' + contextData?.compareList[1]?.id}&id=${contextData?.compareList[2]?.productType + '_' + contextData?.compareList[2]?.id}`;
    return (<>
        <Paper sx={useCompareStyles}  key={"mhjh"}>
            <CardContent sx={{ ml: { lg: 4, md: 0 }, mr: { lg: 4, md: 0 }, display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>

                {_.map(contextData?.compareList, function (item: any, key: any) {
                    return (
                        < >
                            <Badge badgeContent={
                                <img width={20} height={20} alt="close icon" className="close-icon" src='/assets/icon/close.jpeg' onClick={() => contextData.handleCompareProduct(item, 'remove')} />} >
                                <Paper sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex', p: { md: 2, sm: 2, xs: 0.5 }, border: '1px solid #676767', borderRadius: '8px', width: { lg: '22vw' }, maxHeight: '80px' }} variant="outlined" >

                                    <Grid container spacing={2}>
                                        <Grid className='compare-img' item xs={4}>
                                            <img key={key} width={50} height={50} alt="compareimg" src={imageHandle(item?.imageURLs)?.thumbImage[0]} />
                                        </Grid>
                                        {matches && <Grid item className="compare-details" pl={1} xs={8}>
                                            <Typography variant="body2"> Product Code / Name </Typography>
                                            <Typography variant="body1"> {item.productCode} </Typography>
                                        </Grid>}
                                    </Grid>
                                </Paper>

                            </Badge>

                            {key + 1 !== contextData?.compareList?.length && matches &&
                                <img width={40} height={40} alt="compare icon" src="/assets/compareicon.svg" />

                            }
                            {key + 1 !== contextData?.compareList?.length && !matches &&
                                <img width={20} height={20} alt="compare icon" src="/assets/compareicon.svg" />

                            }
                        </>

                    );
                })}

                {contextData?.compareList?.length >= 1 && contextData?.compareList?.length !== 3 && <>
                    {matches ? <img width={40} height={40} alt="compareicon" src="/assets/compareicon.svg" /> :
                        <img width={20} height={20} alt="compareicon" src="/assets/compareicon.svg" />}
                    <Paper sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex', p: { md: 2, sm: 2, xs: 0.5 }, border: '1px dashed #B5B5B5', borderRadius: '8px', width: '20vw', maxHeight: '80px' }} variant="outlined" >
                        <Grid container >
                            <Grid mt={{ sm: 1 }} className='compare-img' item xs={4} md={5} lg={7} sm={6}>
                                <img width={50} height={50} alt="rectangleimg" src='/assets/Rectangle.png' />
                            </Grid>
                            {matches && <Grid item xs={8} sm={6} md={5} lg={5}>
                                <Typography variant="caption" sx={{ color: '#D9D9D9' }}> Product Code / Name</Typography>
                                {
                                    !tabView ?
                                        <Typography sx={{ color: 'rgba(217, 217, 217, 1)' }} mt={1} variant="body1" > _______ </Typography> :
                                        <Typography mt={1} variant="body1" sx={{ color: 'rgba(217, 217, 217, 1)' }}>  ______________ </Typography>

                                }
                            </Grid>}
                        </Grid>
                    </Paper>
                </>}
                {contextData?.compareList?.length == 1 && <>
                    {matches ? <img width={40} height={40} alt="compareicon" src="/assets/compareicon.svg" /> :
                        <img width={20} height={20} alt="compareicon" src="/assets/compareicon.svg" />}
                    <Paper className="compare-emply-details" sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex', p: { md: 2, sm: 2, xs: 0.5 }, border: '1px dashed #B5B5B5', borderRadius: '8px', width: '20vw', maxHeight: '80px' }} variant="outlined" >

                        <Grid container  >
                            <Grid className='compare-img' mt={{ sm: 1 }} md={5} item xs={4} lg={7} sm={6}>
                                <img width={50} height={50} alt="rectangleimg" src='/assets/Rectangle.png' />
                            </Grid>
                            {matches && <Grid item xs={8} md={5} lg={5} sm={6}>
                                <Typography variant="caption" sx={{ color: '#D9D9D9' }}> Product Code / Name</Typography>
                                {
                                    !tabView ?
                                        <Typography sx={{ color: 'rgba(217, 217, 217, 1)' }} mt={1} variant="body1" >  _______ </Typography> :
                                        <Typography mt={1} variant="body1" sx={{ color: 'rgba(217, 217, 217, 1)' }}>  ______________ </Typography>

                                }

                            </Grid>}
                        </Grid>
                    </Paper>
                </>}

                <Box className="compare-button">
                    {matches && tabView ? <Button
                        disabled={contextData?.compareList?.length == 1}
                        onClick={() => router(urls)}
                        sx={{ height: '40px' }} size="large" variant="contained">compare</Button>
                        :
                        <Button
                            disabled={contextData?.compareList?.length == 1}
                            onClick={() => router(urls)}
                            sx={{
                                height: '40px', '&.MuiButtonBase-root': {
                                    minWidth: '40px'
                                }
                            }} size="small" variant="contained"><CompareArrowsIcon /></Button>
                    }
                </Box>

            </CardContent>

        </Paper>
    </>);
};

export default memo(BottomCompareComponent);