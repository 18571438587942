import { toast } from "react-toastify";
import aadService from "../authProvider";
import { fetchAuthLogin } from "./product.service";
import "react-toastify/dist/ReactToastify.css";
import userEvent from "@testing-library/user-event";
export const handleAuthValidate = () => {
    const value = aadService.getValidate();
    switch (value?.stage) {
        case 1: return value;
        case 2: return value;
        case 3: return value;
    }
};
export const setAuthSession = (res: any) => {
    localStorage.setItem('AccessToken', res.data.token);
    localStorage.setItem('refreshToken', res.data.refreshToken);
    localStorage.setItem('role', res.data.user.role);
    localStorage.setItem('fullName', res.data.user.fullName);
    localStorage.setItem('empId', res.data.user.id);
    localStorage.setItem('email',res.data.user.email);
    localStorage.setItem('empNo',res.data.user.empId);
};
