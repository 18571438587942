import { useState, useEffect } from 'react';
import Context from './context';
import _, { result } from 'lodash';
import { useNavigate, useLocation } from "react-router-dom";
import { fetchCompareProductsById, fetchProductDropDownList } from '../services/product.service';
import queryString from 'query-string';
import { toast } from "react-toastify";

const ContextProvider = ({ children }) => {
    const router = useNavigate();
    const [compareList, setCompareList] = useState([]);
    const [filterFields, setFilterFields] = useState([]);
    const [cat, setCat] = useState("rpt");

    // const accessToken = localStorage.getItem("AccessToken");

    const location = useLocation();
    const handleCompareProduct = async (product, actionType, page, type) => {
        if (actionType === "add" && compareList.length <= 2) {

            const concatData = _.concat(compareList, product);
            // const uniqBy = _.uniqBy(concatData, function (list) {
            //     return list;
            // });
            const uniqBy = _.uniqWith(concatData, _.isEqual);
            const mapped = _.map(uniqBy, function (elem) {
                return elem.productType + "_" + elem.id;
            });
            const mappedString = JSON.stringify(mapped);
            localStorage.setItem('productIds', mappedString);
            setCompareList(uniqBy);
        }
        if (actionType === "remove") {
            if (page === "compare") {
                const rejectData = _.reject(compareList, product);
                const mapped = _.map(rejectData, (_.pick, 'id'));
                const mappedString = JSON.stringify(mapped);
                localStorage.setItem('productIds', mappedString);
                setCompareList(rejectData);
                if (rejectData?.length >= 2) {
                    return router(`/compare/${type}?id=${rejectData[0]?.productType + '_' + rejectData[0]?.id}&id=${rejectData[1]?.productType + '_' + rejectData[1]?.id}`);
                }
            } else {
                const rejectData = _.reject(compareList, product);
                const mapped = _.map(rejectData, (_.pick, 'id'));
                const mappedString = JSON.stringify(mapped);
                localStorage.setItem('productIds', mappedString);
                setCompareList(rejectData);

            }

        }
    };

    

    const handleCompareList = async (type) => {
        const res = localStorage.getItem('productIds');
        const ids = JSON.parse(res);
        const queryParams = queryString.parse(location?.search);
        const idValues = queryParams.id || '';
        if (idValues?.length >= 2) {
            await fetchCompareProductsById({ type, ids: idValues }).then((result) => {
                setCompareList(result?.data || []);
            });
        }
        else if (idValues?.length == 0 && (ids !== null || (ids?.length !== 0)) && compareList.length === 0) {
            const result = await fetchCompareProductsById({ type, ids });
            setCompareList(result?.data || []);
        }
    };




    return (
        <Context.Provider value={{ compareList, handleCompareProduct, filterFields, handleCompareList, setCompareList, cat, setCat }} >
            {children}
        </Context.Provider>
    );
};

export default ContextProvider;