import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Request interceptor to add the Authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('AccessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle token refresh
axiosInstance.interceptors.response.use(
  (response) => response, // Pass the response through if no errors
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry || error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
            const refreshToken = localStorage.getItem('refreshToken');
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/refresh?token=${refreshToken}`,);
            const { token } = response.data;

            localStorage.setItem('AccessToken', token);

            // Retry the original request with the new token
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
        } catch (error) {
            // Handle refresh token error or redirect to login
        }
    }
    // If it's not a 401 or 403 or we have already retried, reject the error
    return Promise.reject(error);
  }
);

export default axiosInstance;
