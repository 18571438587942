import { Button, Grid, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import FormInputComponent from '../../../components/upload-component/form-upload';
import { validateForm } from "../../../constants/form-validation";
import UploadUpdateBack from "../../../components/commons/upload-update-back";
import TextFieldsInput from "../../../components/commons/text-fields-input";
import { ProductNewPageStyles } from "../../../styles/upload-component-style";
import _ from "lodash";
import { fetchProductDropDownList, fetchProductsById } from "../../../services/product.service";
import Modal from "../../../components/commons/modal";
import ImageUploadComponent from "../../../components/upload-component/image-upload";
import { INITIAL_PRODUCT_DETAILS } from "../../../constants/Initial_states";
import { imageUploadHandle } from "../../../services/imageupload.service";
import { toast } from 'react-toastify';
import { imageEditHandle } from "../../../services/image-handle.service";
import { useNavigate, useParams } from "react-router-dom";
import Context from "../../../hooks/context";


const ProductUpload = () => {
    const router = useNavigate();
    let { id, type }: any = useParams();
    const [open, setOpen] = useState(false);
    const [formErrors, setFormErrors] = useState<Record<string, string>>({});
    const [dropdownData, setDropDownData] = useState([]);
    const [modalContent, setModalContent] = useState("");
    const [productDetails, setProductDetails] = useState(INITIAL_PRODUCT_DETAILS);
    const contextData: any = useContext(Context);

    // const [selectedCategory, setSelectedCategory] = useState('rpt');
    const handleFormInputChange = (name: any, value: any) => {
        const updatedErrors: any = { ...formErrors };
        if (name === "images") {
            const values: any = productDetails.images;
            if (!_.includes(values, value)) {
                values.push({ ...value });
                setProductDetails({ ...productDetails, [name]: values });
            } else {
                const data = _.filter(values, (obj: any) => obj.id !== value.id);
                setProductDetails({ ...productDetails, [name]: data });
            }
            // <-- 21-11-2023
        }
        else if (name === "productCode") {
            const alphanumericRegex = /^[a-zA-Z0-9]+$/;
            const maxLength = 15;

            if (value.length > maxLength) {
                updatedErrors[name] = `Product Code cannot exceed ${maxLength} characters.`;
            }
            // else if (!alphanumericRegex.test(value) && value !== "") {
            //     updatedErrors[name] = "Product Code / Name must contain alphanumeric characters only.";
            // }
            else {
                if (name === "productCode") {
                    setProductDetails({ ...productDetails, [name]: value });
                    delete updatedErrors[name];
                }
            }
        }
        // else if (name === "skuRange" || name === "overFillCapacity" || name === "weight") {
        //     const alphanumericRegex = /^[0-9]+$/;
        //     if (isNaN(value) && value !== "") {
        //         updatedErrors[name] = name + " Code must contain only numeric characters.";
        //     } else {
        //         if (value.length < 5 && name === "overFillCapacity") {
        //             setProductDetails({ ...productDetails, [name]: value });
        //             delete updatedErrors[name];
        //         }
        //         if (value.length < 5 && name === "skuRange") {
        //             setProductDetails({ ...productDetails, [name]: value });
        //             delete updatedErrors[name];
        //         }
        //         if (value.length < 5 && name === "weight") {
        //             setProductDetails({ ...productDetails, [name]: value });
        //             delete updatedErrors[name];
        //         }
        //         // if (value.length < 7 && name === "price") {
        //         //     setProductDetails({ ...productDetails, [name]: value });
        //         //     delete updatedErrors[name];
        //         // }
        //         if (name === "productCode") {
        //             setProductDetails({ ...productDetails, [name]: value });
        //             delete updatedErrors[name];
        //         }
        //     }
        // }
        else if (name === "moq") {
            const alphanumericRegex = /^[0-9]+$/;
            if (isNaN(value) && value !== "" && contextData.cat ==='gmt') {
                updatedErrors[name] = name + " Code must contain only numeric characters.";
            } else {
                if (value.length < 5 && name === "moq") {
                    setProductDetails({ ...productDetails, [name]: value });
                    delete updatedErrors[name];
                }
                // if (value.length < 7 && name === "price") {
                //     setProductDetails({ ...productDetails, [name]: value });
                //     delete updatedErrors[name];
                // }
                if (name === "productCode") {
                    setProductDetails({ ...productDetails, [name]: value });
                    delete updatedErrors[name];
                }
            }
        }
        // > 21-11-2023
        else {
            // alert(name)
            setProductDetails({ ...productDetails, [name]: value });
            delete updatedErrors[name];
        }
        setFormErrors(updatedErrors);
    };

    const handleProductSubmit = async (label: any) => {
        if (
            productDetails.images && productDetails.images.length >= 1 &&
            validateForm({ productDetails, setFormErrors })) {
            imageUploadHandle({ validateForm, productDetails, setProductDetails, setModalContent, setOpen, label, setFormErrors });
        }
        //  else if (!productDetails.images || productDetails.images.length !== 1 && !validateForm({ productDetails, setFormErrors })) {
        //     validateForm({ productDetails, setFormErrors });
        //     toast.error(`You must upload four images and one GLB , All required fields.`, {
        //         position: toast.POSITION.TOP_RIGHT,
        //     });
        // }
        else if (!productDetails.images || !(productDetails.images.length >= 1)) {
            toast.error(`You must upload atleast one Image`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        // }
        // else if (!validateForm({ productDetails, setFormErrors })) {
        //     toast.error(`Please fill out all required fields.`, {
        //         position: toast.POSITION.TOP_RIGHT,
        //     });
        // }
    };
    const haldeFetchDropDownData = async () => {
        const UrlEndString = "data";
        const data = await fetchProductDropDownList(UrlEndString);
        setDropDownData(data.data);
    };

    const handleFetchProductById = async () => {
        const path = window.location.pathname;
        const regex = /\/product\/(.*?)\/(.*)$/;
        const match = path.match(regex);
        if (match) {
            type = match[1];
            id = match[2];
        }
        await fetchProductsById({ id, type }).then(async (res: any) => {
            const value: any = await imageEditHandle(res?.data?.imageURLs);
            const images = { images: value };

            _.merge(res?.data, images);
            delete res?.data?.imageURLs;
            setProductDetails(res?.data);

        });

    };
    const handleClose = (id: any) => {
        let value;
        setOpen(false);
        if (id === '0') {
            setOpen(false);
            value = INITIAL_PRODUCT_DETAILS;
            value.images = [];
            router("/productView");
            setProductDetails(value);
        } else {
            router(`/product-detail/${type}/${id}`);
        }

    };

    useEffect(() => {
        if (id === "0") {
            haldeFetchDropDownData();
        } else if (id?.length >= 2 && id !== undefined) {
            haldeFetchDropDownData();
            handleFetchProductById();
        }
    }, [id]);

    useEffect(() => {
        setProductDetails({ ...productDetails, category: contextData.cat });
    }, [contextData.cat]);
   
    
    return (
        <>
            <UploadUpdateBack id={id} handleClose={handleClose} selectedCategory={productDetails?.category} setSelectedCategory={contextData.setCat} page="upload" />
            <Box sx={ProductNewPageStyles}>
                <Grid container>
                    <Grid mt={4} item xs={12} sm={5.8} md={5.8}>
                        <Grid p={3} justifyContent="space-between" alignItems="center" xs={12} sm={12} md={12}>
                            <Typography variant="body1">Product Code / Name *</Typography>
                            <div style={{ marginTop: "5px" }}>
                                <TextFieldsInput label="Enter Product Code" size="small" width="100%" name="productCode" type="text" handleValue={handleFormInputChange} value={productDetails?.productCode} error={Boolean(formErrors.productCode)} helperText={formErrors.productCode || ""} placeholder="Enter Product Code / Name" />
                            </div>
                            <Typography variant="body1" mt={3}>Media *</Typography>
                            <Typography className="font-light-wrapper" mt={2} >Add your media. The first Image will be used as the thumbnail in feeds. Drag and drop up-to <span>  4 images/3D glb</span> to create a multi-shot</Typography>
                            <ImageUploadComponent name="images" errors={formErrors} handleValue={handleFormInputChange} productDetails={productDetails} />
                        </Grid>
                    </Grid>
                    <Grid mt={5} className="divider-container" xs={12} sm={0.2} md={0.2}>
                        <Divider orientation="vertical" />
                    </Grid>
                    <Grid mt={{ xs: '-50px', md: 0, sm: 0 }} p={{ xs: 1.5, md: 0, sm: 0 }} xs={12} sm={6} md={6}>
                        <FormInputComponent errors={formErrors} handleFormInputChange={handleFormInputChange} selectedCategory={productDetails?.category} dropdownData={dropdownData} data={productDetails} />
                    </Grid>
                </Grid>
                <Grid className="description-content" container xs={12} sm={12} md={12} >
                    <Grid p={1} item xs={12} sm={4} md={2}>
                        <Typography padding="2px" fontSize="inherit" variant="body1">Product Description</Typography>
                    </Grid>
                    <Grid p={{ xs: 1, md: 0, sm: 0 }} item xs={12} sm={8} md={10}>
                        <TextFieldsInput name="productDescription" multiline rows={4} fullWidth variant="outlined" type="text" handleValue={handleFormInputChange} value={productDetails?.productDescription} error={Boolean(formErrors.productDescription)} helperText={formErrors.productDescription || ""}
                        />
                    </Grid>
                </Grid>
                <Grid className="upload-buttons" container mb={4} spacing={2}>
                    <Grid item>
                        <Button onClick={() => handleClose(id)} className="btn-1" variant="outlined" color="secondary"  >Cancel</Button>
                    </Grid>
                    <Grid item>
                        <Button
                            className="btn-2"
                            onClick={
                                id === '0'
                                    ? () => handleProductSubmit("Upload")
                                    : () => handleProductSubmit("Update")
                            }
                            variant="contained"
                            color="primary"
                        >
                            {
                                id === '0' ? 'Upload' : 'Update'
                            }

                        </Button>
                    </Grid>
                </Grid>

            </Box>
            <Modal
                handleClose={() => handleClose(id)}
                message={`${modalContent} Successful!`}
                content={
                    modalContent === "upload"
                        ? `Your product has been ${modalContent} successfully`
                        : `Product uploaded successfully`
                }
                open={open}
                setOpen={setOpen}
            />
        </>

    );
};
export default ProductUpload;
