import React, { useEffect, useState, ChangeEvent, MouseEvent } from 'react';
import "./add-vendor.css";
import { Button } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DeleteTwoTone, DeleteOutlined } from '@ant-design/icons';
import CustomPagination from '../../components/pagination/customPagination';
// import { type } from 'os';

function AddVendor(type: any) {
    const [vendorData, setVendorData] = useState<any[]>([]);
    const token = localStorage.getItem('AccessToken');
    const [formData, setFormData] = useState<string>('');
    const [isPopupVisible, setPopupVisible] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<string | null>(null);
    const [vendorExists, setVendorExists] = useState<boolean>(false);
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = vendorData.slice(startIndex, endIndex);
    const data = vendorData.length;
    const [userRole, setUserRole] = useState(null);
    const handlePageChange = (page: React.SetStateAction<number>) => {
        setCurrentPage(page);
    };
    // console.log(token)
    useEffect(() => {
        displayVendors();
    }, []);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (value.length <= 100) {
            setFormData(value);
        }
    };

    const resetFormData = () => {
        setFormData('');
    };

    const handlePop = (id: string) => {
        setPopupVisible(true);
        setDeleteId(id);
    };

    const displayVendors = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/vendors/vendorsList`
            , {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then(response => {
                setVendorData(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (formData) {
            axios.post(`${process.env.REACT_APP_BASE_URL}/vendors/addVendor`, { vendorName: formData }
                , {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
                .then(response => {
                    displayVendors();
                    resetFormData();
                    toast.success("Vendor Added Successfully");
                    setVendorExists(false);
                })
                .catch(error => {
                    if (error.response && error.response.status === 409) {
                        setVendorExists(true);
                    } else {
                        console.error('Error fetching form data:', error);
                    }
                });
        }
        else {
            toast.error("Vendor Name cannot be empty");
        }
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/vendors/deleteVendor/${deleteId}`
                , {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            displayVendors();
            toast.success("Vendor Deleted Successfully");
        } catch (error) {
            console.error('Error updating deleteStatus:', error);
        }
        setPopupVisible(false);
    };

    return (
        <div className='vendor-main'>


            <div className='vendor-input'>
                <input
                    type='text'
                    name="vendor"
                    className='input-vendor'
                    value={formData}
                    placeholder='Add a Vendor Name'
                    style={{ width: "300px" }}
                    onChange={handleChange}
                    required
                />
                <Button type="primary" style={{ backgroundColor: "#0084A7", color: "white", height: "35px" }} onClick={handleSubmit}>
                    + Add Vendor
                </Button>
                <Link to={`/product/${type}/0`}>
                    <Button type="primary" style={{ backgroundColor: "#0084A7", color: "white", height: "35px" }}>
                        &#x3c; Back
                    </Button>
                </Link>
            </div>
            {vendorExists && (
                <p style={{ color: 'red', fontSize: "9pt", marginLeft: "5.1%" }}>Vendor with the same name already exists</p>
            )}

            <div className='vr-table'>
                <table className="table table-striped vendor-table">
                    <thead className="thead-dark" >
                        <tr>
                            <th>S.no</th>
                            <th>Vendor Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentData.map((vendor, index) => (
                            <tr key={index}>
                                <td className="counterCell">{index + 1}</td>
                                <td>{vendor.vendorName}</td>
                                <td>
                                    <Button icon={<DeleteTwoTone />} onClick={() => handlePop(vendor.id)} disabled ></Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {isPopupVisible === true ? (
                <div className="del-popup">
                    <p>Are you sure you want to delete?</p>
                    <button onClick={handleDelete}>Yes</button>
                    <button onClick={() => setPopupVisible(false)}>No</button>
                </div>
            ) : ""}
            <CustomPagination
                totalItems={data}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}

            />
        </div>
    );
}

export default AddVendor;