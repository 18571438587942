import { Box, Card, CardContent, CardHeader, CardMedia, Grid, Typography } from "@mui/material";
import _ from "lodash";
import { useProductListStyles } from "../../styles/index-component.style";
import InfiniteScroll from "react-infinite-scroll-component";
import { useContext, useEffect } from "react";
import { SkeleTonLoader } from "../commons/skeleton-loader";
import { useNavigate } from "react-router-dom";
import Context from "../../hooks/context";
import { imageHandle } from "../../services/image-handle.service";
import { Console } from "console";
import { toastErr } from "../../constants/form-constant";
import { toast } from "react-toastify";
const image = [
  "./assets/sample-products/3.png",
  "./assets/sample-products/1.png",
  "./assets/sample-products/2.png",
  "./assets/sample-products/4.png",
];
const cardStyles = {
  boxShadow: 0, height: "100%", borderRadius: '4px',
  cursor: 'pointer'
};
const ProductListComponent = ({ isNextPage, products, fetchNextData, type }: any) => {
  const contextData: any = useContext(Context);
  const router = useNavigate();
  const handleCompareClick = (event: any, product: any) => {
    event.stopPropagation();

    const isProductAlreadyAdded = contextData.compareList.some((item: any) => item.id === product.id);

    if (isProductAlreadyAdded) {
      toast.warn(`This product is already added for comparison.`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (contextData.compareList.length > 2) {
      toast.error(`You cannot add more than three products.`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      contextData.handleCompareProduct(product, 'add');
    }
  };

  // useEffect(() => {
  //   const hasNoImages = _.some(products, (product) => imageHandle(product?.imageURLs)?.thumbImage?.length === 0);
  //   if (hasNoImages) {
  //     window.location.href = "/productView";
  //   }
  // }, []);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          overflowY: 'auto',
          // paddingRight: '15px',
          scrollbarWidth: 'thin', // Firefox scrollbar styling
          scrollbarColor: '#888 #f1f1f1', // Thumb and track color for Firefox
          '&::-webkit-scrollbar': {
            width: '8px', // Webkit scrollbar width
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1', // Track background color
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888', // Thumb color
            borderRadius: '4px', // Rounded corners for thumb
            marginTop:'35vh'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555', // Darker thumb color on hover
          },
        }}
      >
        <InfiniteScroll
          dataLength={products && products?.length || 0}
          next={fetchNextData}
          hasMore={isNextPage}
          loader={<SkeleTonLoader></SkeleTonLoader>}
          height={"99.7vh"}
          style={{ marginTop: '35vh'}}
          scrollThreshold={0.9}
          endMessage={
            <p style={{ textAlign: "center", marginTop: '20vh' }}>
              <b>No Products!!!</b>
            </p>
          }
        >
          {products.length !== 0 &&
            <Grid pt={2} sx={useProductListStyles} container spacing={{ xs: 4, md: 3 }} columns={{ xs: 12, sm: 12, md: 12 }} style={{ paddingLeft: '35px', paddingRight: '30px',bottom:'35vh' }} >
              {_.map(products, function (product: any, key: any) {
                return (<Grid key={key} item xs={12} xl={2} lg={3} sm={6} md={3}>
                  <Card sx={cardStyles}>
                    <CardContent onClick={() => router(`/product-detail/${type}/${product.id}`)}>                                                                                                                       {/*   21-11-23 */}
                      <CardMedia className="card-image" component="img" image={imageHandle(product?.imageURLs)?.thumbImage[0]} alt="product Image" style={{ pointerEvents: 'none' }} />
                      <CardHeader sx={{ pb: 0.5, pl: 0 }}
                        action={<img key={key} alt="compare icon" src="./assets/compareicon.svg" onClick={(e) => handleCompareClick(e, product)}
                          className="mr-4 compareicon" />} title={<Typography sx={{ fontWeight: '700' }} variant="body1" gutterBottom >  {product.productCode} </Typography>} />
                      <Typography variant="body1" pt={0} gutterBottom >  {product?.shapes || " "} </Typography>
                      <Typography variant="body1" gutterBottom>  {product?.price || '-'}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                );
              })}
            </Grid>
          }
        </InfiniteScroll >

      </Box>
    </>
  );
};

export default ProductListComponent;