import { Box, Checkbox, Collapse, FormControl, FormControlLabel, List, ListItemButton, ListItemText, Radio, RadioGroup, Typography, Grid, } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useFilterStyles } from "../../styles/index-component.style";
import { setOptionsFormatObject } from "../commons/label-convert";
import { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import _, { toUpper } from "lodash";
import { findBrowser } from "../../services/browser-navigate.service";
import { fetchProductDropDownList } from "../../services/product.service";
import { SKURANGEFILTER } from "../../constants/filter-opitions.constants";
// import { fetchProductDropDownList } from "../../services/product.service";

const moreLessIcon = {
  marginLeft: '36px', cursor: 'pointer'
};
const closeicon = {
  width: '98%', display: 'flex', justifyContent: 'end', color: '#0062be'
};

const capitalizeFirstLetter = (str: any) => {
  if (str === "skuRange") {
    return 'SKU Range';
  } else {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
};

const FilterOptionsComponent = ({ operatorsRef, fetchProductList, setOperators, operators, contextData, handleCloseDrawyer }: any) => {
  let secondKey: any = '';
  let value: any = '';
  const [openMenus, setOpenMenus] = useState<any[]>([]);
  const [filterFields, setFilterFields] = useState<string[]>([]);

  const [expanded, setExpanded] = useState(false);
  const handleClick = (menuIndex: any) => {
    const isOpen = openMenus.includes(menuIndex);
    if (isOpen) {
      setOpenMenus(openMenus.filter((index) => index !== menuIndex));
    } else {
      setOpenMenus([...openMenus, menuIndex]);
    }
  };
  // const handleChange = (event: React.MouseEvent<HTMLDivElement>, name: any, parentValue: any) => {
  //   event.preventDefault();
  //   const isChecked = operators.filters[parentValue]?.includes(name);
  //   if (!isChecked) {
  //     const value: any = operators.filters[parentValue].push(name);
  //     setOperators({ ...operators, value });
  //   } else {
  //     const value: any = _.remove(operators.filters[parentValue], function (menu: any) {
  //       return menu === name;
  //     });
  //     setOperators({ ...operators, value });
  //     // console.log('Hi');
  //   }
  //   fetchProductList(operators);
  // };

  const handleChange = (event: React.MouseEvent<HTMLDivElement>, name: any, parentValue: any) => {
    event.preventDefault();

    // Ensure that operators.filters[parentValue] is initialized as an array
    if (!operators.filters[parentValue]) {
        operators.filters[parentValue] = [];
    }

    const isChecked = operators.filters[parentValue]?.includes(name);

    if (!isChecked) {
        // Add the item to the array if it's not already checked
        operators.filters[parentValue].push(name);
    } else {
        // Remove the item from the array if it is checked
        _.remove(operators.filters[parentValue], function (menu: any) {
            return menu === name;
        });
    }

    // Update state with the modified filters
    setOperators({ ...operators });

    // Fetch the updated product list
    fetchProductList(operators);
};

  const handleTypeChange = (e: any) => {
    setOperators({ ...operators, type: e.target.value });
    const data: any = operators;
    data.type = e.target.value;
    operatorsRef.current.type = e.target.value;
    fetchProductList(operatorsRef.current);
  };
  const handleFilterOption = (value: any, item: any) => {
    if (item === "skuRange") {
      //22-11-23
      // const dataForDisplay = expanded ? value : value.slice(0, 4);
      // return dataForDisplay;
      return value;
    } else {
      return value;
    }
  };

  useEffect(() => {
    const handleFilterProduct = () => {
      const arr = [""];
      const UrlEndString = "data";
      const keysArr = ["products", "shapes", "containers", "skuRange", "materials", "vendors","category"];

      try {
        fetchProductDropDownList(UrlEndString).then((data) => {
          [data.data.keysTitle, data.data.keys, data.data.skuRange] = [keysArr, _.keys(data.data), SKURANGEFILTER];
          arr.push(data.data);
          // console.log(arr);
          setFilterFields(arr);
        }).catch((e) => {
          console.log(e);
        });
      } catch (error) {
        console.error("Error fetching product dropdown list:", error);
      }
    };
    handleFilterProduct();
  }, []);

  // console.log("filterFields",filterFields);
  
  return (
    <>
      <Grid pt={2} sx={closeicon}>
        <CloseIcon onClick={handleCloseDrawyer} />
      </Grid>
      <Grid sx={useFilterStyles}>
        <Box className="header-title">
          <Typography variant="h6"> Filters</Typography>
          <img alt="filter icon" width={30} height={30} src="/assets/filter.svg" />
        </Box>
        <Box style={{ overflowY: 'scroll',height:contextData?.compareList?.length >= 1 ? '40vh' : '60vh' }} className={findBrowser() === "Firefox" ? "filter-wrapper-fox" : "filter-wrapper"} mt={2} pr={2} >

          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              value={operators.type}
              onChange={(e) => handleTypeChange(e)}
            >
              <FormControlLabel
                value="international"
                control={<Radio size="small" />}
                name="international"
                label={
                  <Typography variant="body2">Search International</Typography>
                }
              />
              <FormControlLabel value="domestic"
                name="domestic"
                control={<Radio size="small" />}
                label={<Typography variant="body2">Search Domestic</Typography>}
              />
            </RadioGroup>
          </FormControl>
          <List sx={{ marginLeft: { xs: '0px', sm: '0px' } }} component="nav" aria-labelledby="nested-list-subheader">
            {filterFields.map((filterOption: any) => {

              return (
                <>
                  {
                    filterOption?.keysTitle?.map((item: any, index: any) => (
                      <div key={index}>
                        <ListItemButton key={index} onClick={() => handleClick(index)}>
                          <ListItemText>
                            <Typography variant="body1">{capitalizeFirstLetter(item)}</Typography>
                          </ListItemText>
                          {

                            openMenus.includes(index) ? (
                              <ExpandLess sx={{ color: "#0062be" }} />
                            ) : (
                              <ExpandMore sx={{ color: "#0062be" }} />
                            )

                          }
                        </ListItemButton>

                        <Collapse in={openMenus.includes(index)} timeout="auto" unmountOnExit>
                          <List className="dropdown-fields" component="div" disablePadding>
                            {
                              handleFilterOption(filterOption[item], item)?.map((option: any, subIndex: any) => {
                                secondKey = Object.keys(option)[1];
                                value = setOptionsFormatObject(option, `${secondKey}`);
                                return (
                                  <>
                                    <ListItemButton onClick={(event: any) => handleChange(event, option.label, item)} key={subIndex}>
                                      <Checkbox checked={operators.filters[item]?.includes(option.label) || false} name={option.label} size="small" />
                                      <ListItemText>
                                        <Typography variant="body2">{value.categoryName ? toUpper(value.label) : value.label}</Typography>
                                      </ListItemText>
                                    </ListItemButton>
                                  </>
                                );
                              })}
                            {item === "skuRange"
                              //22-11-23
                              // && (
                              //   expanded ? (
                              //     <Typography onClick={() => setExpanded(false)} sx={moreLessIcon} >- Less</Typography>
                              //   ) : (
                              //     <Typography variant="body2" onClick={() => setExpanded(true)} sx={moreLessIcon} >+ More</Typography>
                              //   )
                              // )
                            }
                          </List>
                        </Collapse>
                      </div >

                    ))
                  }
                </>
              );
            }
            )}
          </List>
        </Box>
      </Grid >
    </>
  );
};

export default FilterOptionsComponent;

