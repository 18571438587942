// store.ts
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Uses localStorage by default
import barcodeReducer from '../slice/barcodeSlice';

// Persist configuration for the barcode state
const persistConfig = {
  key: 'root',
  storage, // Local storage for persistence
  whitelist: ['barcode'], // Only persist the 'barcode' slice
};

const persistedReducer = persistReducer(persistConfig, barcodeReducer);

const store = configureStore({
  reducer: {
    barcode: persistedReducer, // Persist the barcode slice
  },
});

export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);
export default store;
