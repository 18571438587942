import { useNavigate } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import axios, { AxiosError } from 'axios';
import { Box, Grid, Button, Typography, Snackbar, Slide, SlideProps, SnackbarContent, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, FormGroup, Drawer, Checkbox, Stack, TextField, InputAdornment, Tooltip, TableSortLabel, FormControl, Autocomplete, Chip, MenuItem, ListItemText } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextFieldsInput from "../commons/text-fields-input";
import BarcodeIcon from '../../icons/barcodeIcon.svg';
import SuccessIcon from '../../icons/successIcon.svg';
import CloseIcon from '../../icons/closeIcon.svg';
import UploadIcon from '../../icons/uploadIcon.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '../../icons/SearchIcon.svg';
import './BarcodeListEmp.module.css';
import qs from 'qs';
import { useSelector } from 'react-redux'; // Import useSelector to get state from Redux
import { RootState } from '../../redux/store/store'; // Import RootState from the store
import { toast } from "react-toastify";
import { GetCountries } from "react-country-state-city";
import AddCircleIcon from '@mui/icons-material/AddCircle';

const BarcodeListEmp = () => {

    const barcodeType = useSelector((state: RootState) => state.barcode.barcodeType); // Access barcodeType from Redux store

    const router = useNavigate();
    const [requestSnackbarOpen, setRequestSnackbarOpen] = useState(false);
    const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    // const [selectedSkuRange, setSelectedSkuRange] = useState<string>('');
    const [rows, setRows] = useState<BarcodeItem[]>([]);
    const [countries, setCountries] = useState<CountryOption[]>([]);
    const [selectedCountryCodes, setSelectedCountryCodes] = useState<string[]>([]); // State for selected country ISO codes

    const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
    const [requestDrawerOpen, setRequestDrawerOpen] = useState(false);
    const initialFormValues = {
        brandName: '',
        productName: '',
        description: '',
        sku: '',
    };
    const [formValues, setFormValues] = useState(initialFormValues);
    const [brandData, setBrandData] = useState<DataOption[]>([]);
    const [productData, setProductData] = useState<DataOption[]>([]);
    const [filteredData, setFilteredData] = useState([]);
    const [showFilteredData, setShowFilteredData] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [formErrors, setFormErrors] = useState({
        brandName: '',
        productName: '',
        description: '',
        sku: '',
        countries: '', // Error for country selection
    });

    const [loading, setLoading] = useState(false);
    const [brandPage, setBrandPage] = useState(0);
    const [hasMoreBrandData, setHasMoreBrandData] = useState(true);

    const [order, setOrder] = useState<'asc' | 'desc'>('desc');
    const [orderBy, setOrderBy] = useState<string>('requestedDate');

    // Define type for country selection
    interface CountryOption {
        isoCode: string;
        name: string;
    }

    useEffect(() => {
        const fetchCountries = async () => {
            const allCountries = await GetCountries(); // This returns an array of Country objects
            // Map over allCountries to match the CountryOption interface
            const mappedCountries = allCountries.map((country) => {
                // console.log("country", country); // Log the country object to inspect its structure
                return {
                    isoCode: country.iso2, // Assuming 'iso2' is the 2-letter country code
                    name: country.name, // 'name' is the country name
                };
            });
            setCountries(mappedCountries); // Set the mapped country data
        };

        fetchCountries(); // Call the function to fetch countries
    }, []);

    // Handle select all functionality
    const handleSelectAll = () => {
        if (selectedCountryCodes.length === countries.length) {
            setSelectedCountryCodes([]); // If all are selected, unselect all
        } else {
            const allCountryCodes = countries.map((country) => country.isoCode); // Get all country ISO codes
            setSelectedCountryCodes(allCountryCodes); // Select all countries
        }
    };

    // Handle country selection change
    const handleIndividualChange = (event: any, value: CountryOption[]) => {
        setSelectedCountryCodes(value.map((country) => country.isoCode));

        // Remove the country selection error if at least one country is selected
        if (value.length > 0) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                countries: '', // Clear the countries error
            }));
        }
    };

    // Mapping country codes to country names and displaying them
    const getCountryNamesByCodes = (codes: string[], countries: CountryOption[]): string => {
        return codes
            .map((code) => {
                const country = countries.find((c) => c.isoCode === code); // Find the country by isoCode
                return country ? country.name : ''; // Return the country name or empty string if not found
            })
            .filter((name) => name !== '') // Filter out empty strings
            .join(', '); // Join names with a comma separator
    };

    const [openCountriesDialog, setOpenCountriesDialog] = useState(false);

    const handleShowMoreCountries = () => {
        setOpenCountriesDialog(true);
    };

    const handleDialogClose = () => {
        setOpenCountriesDialog(false);
    };

    interface BarcodeItem {
        sno: string;
        brandName: string;
        productName: string;
        description: string;
        sku: string;
        requestedDate: string;
        assignedDate: string;
        employeeName: string;
        employeeId: string;
        barcode: string;
        assignee: string;
    }

    interface DataOption {
        value: string;
        label: string;
    }

    // Utility function to sort data
    const descendingComparator = (a: any, b: any, orderBy: string) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    const getComparator = (order: 'asc' | 'desc', orderBy: string) => {
        return order === 'desc'
            ? (a: any, b: any) => descendingComparator(a, b, orderBy)
            : (a: any, b: any) => -descendingComparator(a, b, orderBy);
    };

    const stableSort = (array: any[], comparator: (a: any, b: any) => number) => {
        const stabilizedArray = array.map((el, index) => [el, index] as [any, number]);
        stabilizedArray.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedArray.map((el) => el[0]);
    };

    const handleRequestSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const sortedRows = stableSort(showFilteredData ? filteredData : rows, getComparator(order, orderBy));

    const formatDate = (dateString: any) => {
        if (!dateString) {
            return '-';
        }
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    const handleCloseRequestSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setRequestSnackbarOpen(false);
    };

    const handleOpenRequestSnackbar = (event: any) => {
        setRequestSnackbarOpen(true);
    };

    type Anchor = 'right';

    const SlideTransition = (props: SlideProps) => {
        return <Slide {...props} direction="left" />;
    };
    const handleBrandCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSelectedBrands((prev) =>
            prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
        );
    };

    const handleProductCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSelectedProducts((prev) =>
            prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
        );
    };

    const customMenuProps = {
        PaperProps: {
            style: {
                backgroundColor: '#f0f0f0',
                marginBlockStart: '1.5vh',
                boxShadow: 'none',
            },
        },
    };

    const handleFormInputChange = (name: string, value: string) => {
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));

        setFormErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: 'brandName' | 'productName' | 'description') => {
        const value = e.currentTarget.value;
        handleFormInputChange(field, value); // Update state without validation

        // Validate the field value
        if (field === 'brandName') {
            validateField('brandName', value);
        } else if (field === 'productName') {
            validateField('productName', value);
        } else if (field === 'description') {
            validateField('description', value);
        }
    };

    const validateField = (field: 'brandName' | 'productName' | 'description', value: string): string | null => {
        const containsLetters = /[a-zA-Z]/.test(value);  // Must contain at least one letter
        const containsNumbers = /[0-9]/.test(value);  // Must contain at least one number

        // Ensure at least one letter or number is present
        if (!containsLetters && !containsNumbers) {
            return `${field} must contain at least one letter or one number`;
        }

        // Detect special characters (excluding spaces)
        const specialCharRegex = /[!@#$%^&*()_+={}:;"'<>,.?/`~|\\]/;
        const consecutiveSpecialCharRegex = /[!@#$%^&*()_+={}:;"'<>,.?/`~|\\]{2,}/;

        if (specialCharRegex.test(value)) {
            return `${field} cannot contain special characters (except spaces)`;  // Return error message
        }

        // Prevent consecutive special characters
        if (consecutiveSpecialCharRegex.test(value)) {
            return `${field} cannot have consecutive special characters`;
        }

        // Allow spaces
        const containsSpaces = /\s/.test(value);
        if (containsSpaces) {
            // Prevent consecutive spaces (if required)
            const consecutiveSpacesRegex = /\s{2,}/;
            if (consecutiveSpacesRegex.test(value)) {
                return `${field} cannot contain consecutive spaces`;
            }
        }

        return null;  // Return null if no error
    };

    // Handle 'Enter' key press to validate the field when 'Enter' is pressed
    const handleKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            const field = e.currentTarget.name;
            const value = formValues[field as 'brandName' | 'productName' | 'description']?.trim();
            validateField(field as 'brandName' | 'productName' | 'description', value);
        }
    };

    // Validate SKU for special characters and ensure it contains both letters and numbers
    const validateSku = (value: string): string | null => {
        const alphanumericWithSpaceRegex = /^[a-zA-Z0-9\s]+$/; // Allows letters, numbers, and spaces
        const containsLetters = /[a-zA-Z]/.test(value);
        const containsNumbers = /[0-9]/.test(value);

        if (!alphanumericWithSpaceRegex.test(value.trim())) {
            return 'SKU must contain only alphanumeric characters and spaces (no special characters allowed)';
        } else if (!containsLetters || !containsNumbers) {
            return 'SKU must contain both letters and numbers';
        }

        return null; // SKU is valid
    };

    // Handle input change and validate SKU as the user types
    const handleSkuInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        handleFormInputChange('sku', value); // Update state without validation

        // Validate the SKU on each change
        validateSku(value);
    };

    // Handle 'Enter' key press to validate SKU and show error if invalid
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            const value = formValues.sku.trim();
            validateSku(value); // Validate SKU when 'Enter' is pressed
        }
    };

    const handleBrandScroll = async (event: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        const buffer = 100;

        if (scrollHeight - scrollTop <= clientHeight + buffer && !loading && hasMoreBrandData) {
            setLoading(true);
            await getBrandData(true, brandPage + 1);
            setBrandPage(prev => prev + 1);
            setLoading(false);
        }
    };

    const handleRequestBarcode = () => {
        const token = localStorage.getItem('AccessToken');
        // Check if form is valid and if token is available
        if (validateForm() && token) {
            // If barcodeType is 'upc', include selected countries in the request
            const formDataToSubmit = barcodeType === 'upc'
                ? { ...formValues, countries: selectedCountryCodes }
                : { ...formValues };

            // Call the empBarcodeRequest function with the form data
            empBarcodeRequest(formDataToSubmit);

            // Clear selected countries after submission
            setSelectedCountryCodes([]);  // This will reset the countries selection

            // Close the drawer after submission
            setRequestDrawerOpen(false);
        }
    };

    const validateForm = (): boolean => {
        const errors = {
            brandName: '',
            productName: '',
            description: '',
            sku: '',
            countries: '', // Add validation for countries if barcodeType is 'upc'
        };
        let isValid = true;

        // Validate brandName
        if (!formValues.brandName.trim()) {
            errors.brandName = 'Brand name is required and cannot be only spaces';
            isValid = false;
        } else {
            // Check for special characters in brandName
            const specialCharError = validateField('brandName', formValues.brandName);
            if (specialCharError) {
                errors.brandName = specialCharError;
                isValid = false;
            }
        }

        // Validate productName
        if (!formValues.productName.trim()) {
            errors.productName = 'Product name is required and cannot be only spaces';
            isValid = false;
        } else {
            // Check for special characters in productName
            const specialCharError = validateField('productName', formValues.productName);
            if (specialCharError) {
                errors.productName = specialCharError;
                isValid = false;
            }
        }

        // Validate description
        if (!formValues.description.trim()) {
            errors.description = 'Description is required and cannot be only spaces';
            isValid = false;
        } else {
            // Check for special characters in description
            const specialCharError = validateField('description', formValues.description);
            if (specialCharError) {
                errors.description = specialCharError;
                isValid = false;
            }
        }

        // Validate SKU
        if (!formValues.sku.trim()) {
            errors.sku = 'SKU range is required and cannot be only spaces';
            isValid = false;
        } else {
            const skuError = validateSku(formValues.sku);
            if (skuError) {
                errors.sku = skuError;
                isValid = false;
            }
        }

        // If barcodeType is 'upc', validate that at least one country is selected
        if (barcodeType === 'upc' && selectedCountryCodes.length === 0) {
            errors.countries = 'Please select at least one country';
            isValid = false;
        }

        setFormErrors(errors); // Update form errors state
        return isValid;
    };

    const list = (anchor: Anchor) => (
        <Box
            sx={{ width: 350, overflowY: 'scroll' }}
            role="presentation"
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1.5vh' }}>
                <Typography variant="h6">
                    Filters
                </Typography>
                <Box>
                    <span onClick={() => setFilterDrawerOpen(false)} style={{ color: 'red', cursor: 'pointer' }}>Close</span>
                    <img onClick={() => setFilterDrawerOpen(false)} src={CloseIcon} style={{ cursor: 'pointer' }} alt="close" />
                </Box>
            </Box>
            <Grid item xs={3} style={{ marginInline: '3vh' }}>
                {/* <TextField
                    id="fsrch"
                    name="fsrch"
                    placeholder="Search"
                    size="small"
                    style={{ backgroundColor: '#f0f0f0', borderRadius: '2vh' }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <img src={SearchIcon} alt="search" style={{ marginInlineStart: '-1vh' }} />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none',
                            },
                        }
                    }}
                    onChange={handleSearchChange}
                /> */}
            </Grid>
            <Accordion sx={{
                outline: 'none',
                boxShadow: 'none',
                marginBlockStart: '2vh'
            }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="body1">Brand</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ marginBlockStart: '-1.9vh', marginBlockEnd: '-3vh' }}>
                    <Box
                        sx={{
                            maxHeight: '200px',
                            overflowY: 'auto',
                        }}
                        onScroll={handleBrandScroll}
                    >
                        <FormGroup>
                            {brandData.map((brand, index) => (
                                <FormControlLabel
                                    sx={{
                                        marginBlock: '-3.5px',
                                        whiteSpace: 'normal',       // Allows wrapping onto multiple lines
                                        wordBreak: 'break-word',    // Breaks words if they are too long
                                    }}
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={selectedBrands.includes(brand.value)}
                                            onChange={handleBrandCheckboxChange}
                                            value={brand.value}
                                        />
                                    }
                                    label={brand.label}
                                />
                            ))}
                        </FormGroup>
                        {loading && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1vh' }}>
                                <Typography variant="body2">Loading more...</Typography>
                            </Box>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{
                outline: 'none',
                boxShadow: 'none',
            }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography variant="body1">Product</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ marginBlockStart: '-1.9vh', marginBlockEnd: '-3vh' }}>
                    <Box
                        sx={{
                            maxHeight: '200px',
                            overflowY: 'auto',
                        }}
                    >
                        <FormGroup>
                            {productData.map((option, idx) => (
                                <FormControlLabel
                                    sx={{ marginBlock: '-7px' }}
                                    key={idx}
                                    control={
                                        <Checkbox
                                            checked={selectedProducts.includes(option.value)}
                                            onChange={handleProductCheckboxChange}
                                            value={option.value}
                                        />
                                    }
                                    label={option.label}
                                />
                            ))}
                        </FormGroup>
                        {loading && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1vh' }}>
                                <Typography variant="body2">Loading more...</Typography>
                            </Box>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Stack spacing={2} direction="row" style={{ marginInlineStart: '25vh', marginBlockStart: '5vh' }}>
                <Button variant="outlined" sx={{ textTransform: 'none' }} onClick={clearFilters}>Clear</Button>
                <Button variant="contained" onClick={handleApplyClick} sx={{ textTransform: 'none' }}>Apply</Button>
            </Stack>
        </Box>
    );

    const request = (anchor: Anchor) => (
        <Box
            sx={{ width: 450, overflowY: 'scroll' }}
            role="presentation"
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1.5vh' }}>
                <Typography variant="h6">
                    Request Barcode
                </Typography>
                <Box>
                    <span onClick={() => setRequestDrawerOpen(false)} style={{ color: 'red', cursor: 'pointer' }}>Close</span>
                    <img onClick={() => setRequestDrawerOpen(false)} src={CloseIcon} style={{ cursor: 'pointer' }} alt="close" />
                </Box>
            </Box>
            <Grid sx={{ marginInline: '2vh' }}>
                <Box sx={{ marginBlockEnd: '2vh' }}>
                    <Typography style={{ marginInlineStart: "2vh" }} variant="body2">Brand </Typography>
                    <div style={{ marginInlineStart: '1.3vh', }}>
                        <input style={{
                            width: '100%',
                            borderRadius: '1vh',
                            border: 'none',
                            marginBlockStart: '2vh',
                            padding: '1.2vh',
                            background: '#f0f0f0',
                        }}
                            placeholder="Enter Brand" width="100%"
                            // onChange={(e) => handleFormInputChange('brandName', e.target.value)}
                            name="brandName" type="text" minLength={1} maxLength={20} value={formValues.brandName}
                            onChange={(e) => handleInputChange(e, 'brandName')}
                        // onInput={(e) => handleInputChange(e, 'brandName')}
                        />
                        {formErrors.brandName && (
                            <Typography variant="body2" color="error" sx={{ marginBlockStart: '2px' }}>
                                {formErrors.brandName}
                            </Typography>
                        )}
                    </div>
                </Box>
                <Box sx={{ marginBlockEnd: '2vh' }}>
                    <Typography style={{ marginInlineStart: "2vh" }} variant="body2">Product </Typography>
                    <div style={{ marginInlineStart: '1.3vh', }}>
                        <input style={{
                            width: '100%',
                            borderRadius: '1vh',
                            border: 'none',
                            marginBlockStart: '2vh',
                            padding: '1.2vh',
                            background: '#f0f0f0',
                        }}
                            placeholder="Enter Product" width="100%"
                            // onChange={(e) => handleFormInputChange('productName', e.target.value)}
                            onChange={(e) => handleInputChange(e, 'productName')}
                            name="productName" type="text" minLength={1} maxLength={20} value={formValues.productName}
                        />
                        {formErrors.productName && (
                            <Typography variant="body2" color="error" sx={{ marginBlockStart: '2px' }}>
                                {formErrors.productName}
                            </Typography>
                        )}
                    </div>
                </Box>
                <Box sx={{ marginBlockEnd: '2vh' }}>
                    <Typography style={{ marginInlineStart: "2vh" }} variant="body2">Description </Typography>
                    <div style={{ marginInlineStart: '1.3vh', }}>
                        <input style={{
                            width: '100%',
                            borderRadius: '1vh',
                            border: 'none',
                            marginBlockStart: '2vh',
                            padding: '1.2vh',
                            background: '#f0f0f0',
                        }}
                            placeholder="Enter Description" width="100%"
                            // onChange={(e) => handleFormInputChange('description', e.target.value)}
                            onChange={(e) => handleInputChange(e, 'description')}  // Handle input change for validation
                            // onInput={(e) => handleInputChange(e, 'description')} // Handle input to prevent special characters
                            name="description" type="text" minLength={1} maxLength={50} value={formValues.description}
                            // onInput={handleBrandInput}  // Handle input to prevent special characters
                            onKeyDown={handleKeyDownHandler} // Handle 'Enter' key to check if valid
                        />
                        {formErrors.description && (
                            <Typography variant="body2" color="error" sx={{ marginBlockStart: '2px' }}>
                                {formErrors.description}
                            </Typography>
                        )}
                    </div>
                </Box>
                <Box sx={{ marginBlockEnd: '2vh' }}>
                    <Typography style={{ marginInlineStart: "2vh" }} variant="body2">SKU Range</Typography>
                    <div style={{ marginInlineStart: '1.3vh' }}>
                        <input style={{
                            width: '100%',
                            borderRadius: '1vh',
                            border: 'none',
                            marginBlockStart: '2vh',
                            padding: '1.2vh',
                            background: '#f0f0f0',
                        }}
                            placeholder="Enter SKU" width="100%"
                            // onChange={(e) => handleFormInputChange('sku', e.target.value.trim())}
                            onChange={(e) => handleSkuInput(e)} // Handle input to prevent special characters
                            name="sku" type="text"
                            value={formValues.sku}
                            minLength={1}
                            maxLength={20}
                            title="SKU should only contain letters, numbers, underscores, and dashes"
                            onInput={handleSkuInput}  // Handle input to prevent special characters
                            onKeyDown={handleKeyDown} // Handle 'Enter' key to check if valid
                        />
                        {formErrors.sku && (
                            <Typography variant="body2" color="error" sx={{ marginBlockStart: '2px' }}>
                                {formErrors.sku}
                            </Typography>
                        )}
                    </div>
                </Box>

                {/* Country Multiselect list */}
                {
                    barcodeType === 'upc' &&
                    <Box sx={{ marginBlockEnd: "2vh" }}>
                        <Typography style={{ marginInlineStart: "1.5vh" }} variant="body2">
                            Country Selection
                        </Typography>

                        {/* Autocomplete for multi-selection */}
                        <FormControl fullWidth sx={{ marginTop: "2vh", padding: '1.2vh' }}>
                            <Autocomplete
                                multiple
                                id="country-select"
                                options={[{ isoCode: "select-all", name: "Select All" }, ...countries]} // Add "Select All" as the first item in the options list
                                value={countries.filter((country) =>
                                    selectedCountryCodes.includes(country.isoCode)
                                )}
                                onChange={handleIndividualChange}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) => option.isoCode === value.isoCode}
                                renderInput={(params) => <TextField {...params} label="Select Countries" />}
                                renderTags={(value, getTagProps) => {
                                    // Maximum number of countries to show before truncating
                                    const maxTagsToShow = 2;

                                    // If all countries are selected, show "All countries"
                                    if (selectedCountryCodes.length === countries.length) {
                                        return (
                                            <Chip label="All countries" {...getTagProps({ index: 0 })} />
                                        );
                                    }

                                    // If there are more than maxTagsToShow countries selected, show a "+" icon to indicate more
                                    if (value.length > maxTagsToShow) {
                                        const visibleCountries = value.slice(0, maxTagsToShow);
                                        return [
                                            ...visibleCountries.map((option, index) => (
                                                <Chip label={option.name} {...getTagProps({ index })} />
                                            )),
                                            <Chip
                                                icon={<AddCircleIcon />}
                                                label={`+${value.length - maxTagsToShow}`} // Display how many additional countries are selected
                                                {...getTagProps({ index: value.length - 1 })}
                                            />
                                        ];
                                    }

                                    // Render all selected countries if it's below maxTagsToShow
                                    return value.map((option, index) => (
                                        <Chip label={option.name} {...getTagProps({ index })} />
                                    ));
                                }}
                                renderOption={(props, option) => {
                                    if (option.isoCode === "select-all") {
                                        return (
                                            <MenuItem {...props} key="select-all" onClick={handleSelectAll}>
                                                <Checkbox
                                                    checked={selectedCountryCodes.length === countries.length}
                                                    onChange={handleSelectAll}
                                                />
                                                <ListItemText primary="Select All" />
                                            </MenuItem>
                                        );
                                    }

                                    // Render country options
                                    const isSelected = selectedCountryCodes.includes(option.isoCode);
                                    return (
                                        <MenuItem {...props} key={option.isoCode}>
                                            <Checkbox checked={isSelected} />
                                            <ListItemText primary={option.name} />
                                        </MenuItem>
                                    );
                                }}
                            />
                        </FormControl>
                        {formErrors.countries && (
                            <Typography variant="body2" color="error" sx={{ marginBlockStart: '2px' }}>
                                {formErrors.countries}
                            </Typography>
                        )}
                    </Box>
                }
            </Grid>
            <Stack spacing={2} direction="row" style={{ marginInlineStart: '57%', marginBlockStart: '5vh' }}>
                <Button variant="outlined" sx={{ textTransform: 'none' }} onClick={handleRequestCancel}>Cancel</Button>
                <Button variant="contained" onClick={handleRequestBarcode} sx={{ textTransform: 'none' }}>Submit</Button>
            </Stack>
        </Box>
    );

    const resetFormValues = () => {
        setFormValues(initialFormValues);
        setFormErrors({
            brandName: '',
            productName: '',
            description: '',
            sku: '',
            countries: ''
        });
    };

    const handleRequestCancel = () => {
        resetFormValues();
        setRequestDrawerOpen(false);
    };

    const snackbarRequestContentProps = {
        message: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <img src={SuccessIcon} alt="" />&nbsp;
                    <span style={{ marginLeft: '8px' }}>Request submitted successfully</span>
                </div>&nbsp;&nbsp;
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img src={CloseIcon} onClick={handleCloseRequestSnackbar} style={{ cursor: 'pointer' }} alt="" />
                </div>
            </div>
        ),
        sx: {
            backgroundColor: 'white',
            color: 'green',
            borderRadius: '8px',
            padding: '1.5vh'
        },
    };

    // useEffect(() => {
    //     getBarcodeList();
    // }, []);

    useEffect(() => {
        if (selectedBrands.length > 0) {
            getProductData(selectedBrands);
        } else {
            setProductData([]);
        }
    }, [selectedBrands]);

    useEffect(() => {
        getBrandData(false);
    }, []);

    const getBarcodeList = async () => {
        const token = localStorage.getItem('AccessToken');
        const userId = localStorage.getItem('empId');
        try {
            let apiUrl = '';

            if (barcodeType === 'ean') {
                apiUrl = `${process.env.REACT_APP_BASE_URL}/barcodes/filterOfBarcode?userId=${userId}`;
            } else if (barcodeType === 'upc') {
                apiUrl = `${process.env.REACT_APP_BASE_URL}/barcodesInternational/filterOfBarcodeInternational?userId=${userId}`;
            } else {
                console.error('Invalid barcode type');
                return;
            }

            const barcodeList = await axios.get(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setRows(barcodeList?.data);
        } catch (error) {
            console.error('Error fetching employee barcode list:', error);
        }
    };



    const empBarcodeRequest = async ({ brandName, productName, description, sku, countries }: any) => {
        const employeeName = localStorage.getItem('fullName');
        const employeeId = localStorage.getItem('empId');
        const empNo = localStorage.getItem('empNo');
        const token = localStorage.getItem('AccessToken');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        if (token && token.length > 0) {
            try {
                let apiUrl = '';
                // Include the countries parameter in the request if barcodeType is 'upc'
                const countriesParam = countries ? countries.join(',') : '';

                if (barcodeType === 'ean') {
                    apiUrl = `${process.env.REACT_APP_BASE_URL}/barcodes/createRequest?brandName=${brandName}&productName=${productName}&description=${description}&sku=${sku}&employeeName=${employeeName}&employeeId=${employeeId}&empNo=${empNo}`;
                } else if (barcodeType === 'upc') {
                    apiUrl = `${process.env.REACT_APP_BASE_URL}/barcodesInternational/createRequestInternational?brandName=${brandName}&productName=${productName}&description=${description}&sku=${sku}&employeeName=${employeeName}&employeeId=${employeeId}&empNo=${empNo}&countries=${countriesParam}`;
                } else {
                    console.error('Invalid barcode type');
                    return;
                }

                const responseData = await axios.post(apiUrl, null, {
                    headers: headers
                });

                setBrandPage(0);
                getBrandData(false);
                getBarcodeList();

                handleOpenRequestSnackbar(false);
                handleRequestCancel();
            } catch (error: unknown) {
                console.log("error", error);

                if (error instanceof AxiosError) {
                    console.log("AxiosError", error);
                    // If the error has a response and data, display the error message from the server
                    if (error.response?.data && error.response?.status === 409) {
                        toast.error(`${error.response.data}`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setRequestDrawerOpen(true);
                    }
                }
                console.error('Error while sending request', error);
            }
        }
    };

    // const getBrandData = async () => {
    //     const token = localStorage.getItem('AccessToken');
    //     const userId = localStorage.getItem('empId');
    //     try {
    //         const brandData = await axios.get(`https://packaginglibrary-dev-backend.citpl.info/api/v1/barcodes/brandsOfBarcode?userId=${userId}`,
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                 }
    //             }
    //         );
    //         const formattedData = brandData.data.map((item: string) => ({
    //             value: item,
    //             label: item
    //         }));
    //         setBrandData(formattedData);
    //     }
    //     catch (error) {
    //         console.error('Brand not available', error);
    //     }
    // };

    const getBrandData = async (isDuplicate: boolean, page = 0) => {
        const token = localStorage.getItem('AccessToken');
        const userId = localStorage.getItem('empId');
        try {
            let apiUrl = '';

            if (barcodeType === 'ean') {
                apiUrl = `${process.env.REACT_APP_BASE_URL}/barcodes/brandsOfBarcode`;
            } else if (barcodeType === 'upc') {
                apiUrl = `${process.env.REACT_APP_BASE_URL}/barcodesInternational/brandsOfBarcodeInternational`;
            } else {
                console.error('Invalid barcode type');
                return;
            }

            const brandData = await axios.get(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: { page, userId },
                paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
            });

            const formattedData = brandData.data.map((item: string) => ({
                value: item,
                label: item
            }));

            if (formattedData.length === 0) {
                setHasMoreBrandData(false);
            } else {
                if (isDuplicate) {
                    setBrandData(prev => [...prev, ...formattedData]); // Append new data for pagination
                } else {
                    setHasMoreBrandData(true);
                    // setBrandData(prev => [...prev, ...formattedData]);
                    setBrandData(formattedData); // Append new data for pagination
                }

            }
        } catch (error) {
            console.error('Brand data not available:', error);
        }
    };


    const getProductData = async (brandNames: string[]) => {
        const token = localStorage.getItem('AccessToken');
        const userId = localStorage.getItem('empId');
        try {

            let apiUrl = '';

            if (barcodeType === 'ean') {
                apiUrl = `${process.env.REACT_APP_BASE_URL}/barcodes/productsOfBarcode`;
            } else if (barcodeType === 'upc') {
                apiUrl = `${process.env.REACT_APP_BASE_URL}/barcodesInternational/productsOfBarcodeInternational`;
            } else {
                console.error('Invalid barcode type');
                return;
            }

            const responses = await Promise.all(
                brandNames.map((brandName) =>
                    axios.get(`${apiUrl}?brandName=${brandName}&userId=${userId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                )
            );
            const formattedData = responses.flatMap(response =>
                response.data.map((item: string) => ({
                    value: item,
                    label: item
                }))
            );
            setProductData(formattedData);
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    };

    const getFilteredData = async (brandNames: any, productNames: any) => {
        const token = localStorage.getItem('AccessToken');
        const userId = localStorage.getItem('empId');
        try {
            let apiUrl = '';

            if (barcodeType === 'ean') {
                apiUrl = `${process.env.REACT_APP_BASE_URL}/barcodes/filterOfBarcode`;
            } else if (barcodeType === 'upc') {
                apiUrl = `${process.env.REACT_APP_BASE_URL}/barcodesInternational/filterOfBarcodeInternational`;
            } else {
                console.error('Invalid barcode type');
                return;
            }

            const filteredData = await axios.get(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    brandNames: brandNames.join(','),
                    productNames: productNames.join(','),
                    userId: userId
                }
            });
            setFilteredData(filteredData.data);
            setShowFilteredData(true);
            setFilterDrawerOpen(false);
            // console.log(filteredData.data);
        }
        catch (error) {
            console.error('err', error);
        }
    };

    const clearFilters = () => {
        setSelectedBrands([]);
        setSelectedProducts([]);
        setFilteredData([]);
        setShowFilteredData(false);
    };


    // const handleApplyClick = () => {
    //     const selectedBrand = selectedCheckboxes.find((item) =>
    //         brandData.some((brand) => brand.value === item)
    //     );
    //     const selectedProduct = selectedCheckboxes.find((item) =>
    //         productData.some((product) => product.value === item)
    //     );

    //     getFilteredData(selectedBrand, selectedProduct);
    // };

    const handleApplyClick = () => {
        if (selectedBrands.length > 0 || selectedProducts.length > 0) {
            getFilteredData(selectedBrands, selectedProducts);
        }
    };


    const getSearchData = async (searchText: any) => {
        const token = localStorage.getItem('AccessToken');
        const userId = localStorage.getItem('empId');
        setRows([]);
        try {
            let apiUrl = '';

            if (barcodeType === 'ean') {
                apiUrl = `${process.env.REACT_APP_BASE_URL}/barcodes/filterOfBarcode?searchText=${searchText}&userId=${userId}`;
            } else if (barcodeType === 'upc') {
                apiUrl = `${process.env.REACT_APP_BASE_URL}/barcodesInternational/filterOfBarcodeInternational?searchText=${searchText}&userId=${userId}`;
            } else {
                console.error('Invalid barcode type');
                return;
            }

            const res = await axios.get(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setRows(res.data);
        }
        catch (error) {
            console.error('err', error);
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm.length == 0) {
                setRows([]);
            }
        }, 200);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm) {
                getSearchData(searchTerm);
            } else {
                getBarcodeList();
            }
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);


    return (
        <Grid item xs={9} sx={{ marginTop: '80px' }}>
            <Box >
                <Box className="back-icon" sx={{ marginBlockEnd: '1vh', display: 'flex', alignItems: 'center' }} >
                    <img className="icon" onClick={() => router('/')} alt="back icon" style={{ marginLeft: '4vh', height: '24px', width: '24px', cursor: 'pointer' }} src="/assets/icon/back_arrow.svg" />
                    <Typography onClick={() => router('/')} sx={{ marginLeft: '0%', fontSize: '17px', cursor: 'pointer' }}>Back</Typography>
                </Box>
                <Grid container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', marginBlockEnd: '4vh' }}>
                    <Grid sx={{ marginLeft: '5vh' }} item xs={2}>
                        <Typography variant="h6">My Dashboard</Typography>
                    </Grid>
                    <Grid sx={{ display: 'flex', flexDirection: 'row', gap: '3vh', marginInlineEnd: '5vh', alignItems: 'center' }} item xs={5.5}>
                        <Grid item xs={5}>
                            <TextField
                                id="fsrch"
                                name="fsrch"
                                placeholder="Search"
                                size="small"
                                style={{ backgroundColor: '#f0f0f0', borderRadius: '2vh' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={SearchIcon} alt="search" style={{ marginInlineStart: '-1vh' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: 'none',
                                        },
                                    },
                                }}
                                onChange={handleSearchChange}
                            />
                        </Grid>
                        <img className="icon" onClick={() => setFilterDrawerOpen(true)} alt="back icon" style={{ marginLeft: '0vh', height: '24px', width: '24px', cursor: 'pointer' }} src="/assets/filterIcon.svg" />
                        <Button sx={{ borderRadius: '1vh', textTransform: 'none' }} variant="outlined" onClick={() => setRequestDrawerOpen(true)} > Request Barcode</Button>
                        <Button sx={{ borderRadius: '1vh', textTransform: 'none' }} variant="outlined" onClick={() => router('/barcodeEmployee')}> Barcode List</Button>
                    </Grid>
                </Grid>

                {/* Table List Sorting */}
                <TableContainer className="custom-scrollbar" component={Paper} style={{
                    marginInlineStart: '5vh', width: '95%', maxHeight: '420px', overflowY: 'scroll', overflowX: 'scroll'
                }}>
                    <Table sx={{ width: '100%' }} aria-label="simple table">
                        <TableHead style={{ backgroundColor: '#f0f0f0' }}>
                            <TableRow style={{ position: 'sticky', top: 0, backgroundColor: '#f0f0f0', zIndex: 1 }}>
                                <TableCell sx={{ fontWeight: 'bold' }}>S.No</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Brand</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Product</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
                                {
                                    barcodeType === 'upc' &&
                                    <TableCell sx={{ fontWeight: 'bold' }}>Country</TableCell>
                                }
                                <TableCell sx={{ fontWeight: 'bold' }}>
                                    <TableSortLabel
                                        active={orderBy === 'requestedDate'}
                                        direction={orderBy === 'requestedDate' ? order : 'asc'}
                                        onClick={() => handleRequestSort('requestedDate')}
                                    >
                                        Requested Date
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Assigned Date</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>SKU</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Barcode</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Assignee</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(showFilteredData ? filteredData : rows).length > 0 ? (
                                sortedRows.map((barcodeList, index) => (
                                    <TableRow key={barcodeList.sno}>
                                        <TableCell sx={{ padding: '3vh' }} align="right">{index + 1}</TableCell>
                                        <TableCell sx={{ padding: '3vh' }} component="th" scope="row">
                                            {barcodeList?.brandName}
                                        </TableCell>
                                        <TableCell sx={{ padding: '3vh' }}>{barcodeList.productName}</TableCell>
                                        <TableCell sx={{ maxWidth: 100, whiteSpace: 'normal', wordWrap: 'break-word', overflow: 'visible', textOverflow: 'ellipsis' }}>
                                            <Tooltip title={barcodeList.description} arrow placement="left-start">
                                                <span>{barcodeList.description}</span>
                                            </Tooltip>
                                        </TableCell>
                                        {
                                            barcodeType === 'upc' &&
                                            <TableCell sx={{ maxWidth: 200, whiteSpace: 'normal', wordWrap: 'break-word', overflow: 'visible', textOverflow: 'ellipsis' }}>
                                                <Tooltip title={barcodeList.description} arrow placement="left-start">
                                                    {/* <span>{`India,Aland Islands,Angola,Brazil`}</span> */}
                                                    <span>{getCountryNamesByCodes(barcodeList.countries,countries)}</span>
                                                </Tooltip>
                                            </TableCell>
                                        }
                                        <TableCell sx={{ padding: '3vh' }}>{formatDate(barcodeList.requestedDate)}</TableCell>
                                        <TableCell>
                                            {barcodeList.assignedDate ? (
                                                <span style={{
                                                    color: 'green',
                                                    backgroundColor: '#1C98191A', borderRadius: '5px',
                                                    padding: '4px'
                                                }}>
                                                    {formatDate(barcodeList.assignedDate)}
                                                </span>
                                            ) : (
                                                <span>-</span>
                                            )}
                                        </TableCell>
                                        <TableCell sx={{ padding: '3vh' }}>{barcodeList.sku}</TableCell>
                                        <TableCell>
                                            {barcodeList.barcode === 'InProgress' ? (
                                                <span style={{
                                                    color: '#FFA928',
                                                    backgroundColor: '#FFA9281A',
                                                    borderRadius: '5px',
                                                    padding: '4px'
                                                }}>{barcodeList.barcode}</span>
                                            ) : (
                                                <>
                                                    <span>{barcodeList.barcode}</span>&nbsp;&nbsp;
                                                    <img src={BarcodeIcon} alt="barcode" style={{ cursor: 'pointer' }} />
                                                </>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {barcodeList.assignee === 'Not Assigned' ? (
                                                <span style={{
                                                    color: '#FF3737',
                                                    backgroundColor: '#FF37371A',
                                                    borderRadius: '5px',
                                                    padding: '4px'
                                                }}>
                                                    {barcodeList.assignee}
                                                </span>
                                            ) : (
                                                <span>{barcodeList.assignee}</span>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={9} sx={{ textAlign: 'center', padding: '3vh' }}>
                                        No Data
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Snackbar open={requestSnackbarOpen} autoHideDuration={3000} onClose={handleCloseRequestSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} TransitionComponent={SlideTransition}>
                <SnackbarContent {...snackbarRequestContentProps} />
            </Snackbar>
            <Drawer
                anchor='right'
                open={filterDrawerOpen}
                onClose={() => setFilterDrawerOpen(false)}
            >
                {list('right')}
            </Drawer>
            <Drawer
                anchor='right'
                open={requestDrawerOpen}
                onClose={() => setRequestDrawerOpen(false)}
            >
                {request('right')}
            </Drawer>
        </Grid>
    );
};

export default BarcodeListEmp;

